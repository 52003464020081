let Locale = {
    init : () => {
        Locale.Switch();
    },
    Switch : () => {

        if ($('.lang-item-selected').length) {
            $d.off('click', '.lang-item-selected').on('click', '.lang-item-selected', function(){
                if ($('.lang-items-list').is(':visible')) {
                    $('.lang-items-list').slideUp(200); return;
                }else {
                    $('.lang-items-list').slideDown(200); return;
                }
            });
        }
    },
    Active : () => {
        if ($('input[name="params_locale_switch"]').length) {
            $d.off('change', 'input[name="params_locale_switch"]').on('change', 'input[name="params_locale_switch"]', function(){
                let data = {
                    url : $(this).attr('ajax-url'),
                    checked: $(this).prop('checked') ? 1 : 0,
                    switch_locale : true
                }
                require('./AdminParams').AjaxUpdate(data);
                return;
            });
        }
    }
}
module.exports = Locale;