let UserPrivilege = {
    init: () => {
        let elements = document.getElementsByClassName('check-privileges-fieldset');
        Array.from(elements).forEach(function(element) {
            element.addEventListener('change', UserPrivilege.checkFieldset);
        });
    },
    checkFieldset: () => {
        let elem = event.target;
        let fieldset = elem.value;
        let checked = elem.checked;

        let elements = document.getElementsByClassName('checkbox-' + fieldset);
        Array.from(elements).forEach(function(element) {
            element.checked = checked;
        });
    },
}
module.exports = UserPrivilege;
