let AdminEmailTemplate = {
    init : () => {
        AdminEmailTemplate.Active();
        AdminEmailTemplate.Actions();

        console.log('*** AdminEmailTemplate ***')
    },

    Actions : () => {

        function copy(element) {
            element.select();
            document.execCommand("copy");

            element.val('copied');
        }
        
        $d.off('click', '.copy').on('click', '.copy', function(e){

            e.preventDefault();

            let $that = $(this);

            let value = $that.parent().children('input').val()
            
            copy($that.parent().children('input'));

            setTimeout(function(){ $that.parent().children('input').val(value); return; },1000);
        });


        $d.off('click', '.email-dynamic-word-notice').on('click', '.email-dynamic-word-notice', function(){
            if ($('.email-dynamic-word-notice-info').is(':visible')) {
                $('.email-dynamic-word-notice-info').slideUp(300);
                $(this).find('.icon').removeClass('icon-open');
            } else {
                $('.email-dynamic-word-notice-info').slideDown(300);
                $(this).find('.icon').addClass('icon-open');
            }
        });
    },

    Active : () => {
        if ($('input[name="email_template_active"]').length) {
            $d.off('change', 'input[name="email_template_active"]').on('change', 'input[name="email_template_active"]', function(){
                $(this).parent().parent('form').trigger('submit'); return;
            });
        }
    }
}
module.exports = AdminEmailTemplate;