const script = document.createElement('script');
script.type = 'text/javascript';
script.src = '//mozilla.github.io/pdf.js/build/pdf.js';
document.querySelector('footer').after(script);

script.onload = function() {
    var container = document.getElementById('pdfjs-container');

    /**********************************************/
    /* https://mozilla.github.io/pdf.js/examples/ */
    /**********************************************/

    // atob() is used to convert base64 encoded PDF to binary-like data.
    // (See also https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/
    // Base64_encoding_and_decoding.)
    var pdfData = atob(container.dataset.file);

    // If absolute URL from the remote server is provided, configure the CORS
    // header on that server.
    //var url = location.protocol + '//' + location.hostname + '/' + container.dataset.filepath;

    // Loaded via <script> tag, create shortcut to access PDF.js exports.
    var pdfjsLib = window['pdfjs-dist/build/pdf'];

    // The workerSrc property shall be specified.
    pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';

    // Asynchronous download of PDF
    //var loadingTask = pdfjsLib.getDocument(url);
    var loadingTask = pdfjsLib.getDocument({data: pdfData});
    console.log(loadingTask);
    loadingTask.promise.then(function(pdf) {

        var ul = document.createElement('ul');
        ul.setAttribute('class', 'nav nav-tabs');
        ul.setAttribute('role', 'tablist');
        container.appendChild(ul);
        var tabContent = document.createElement('div');
        tabContent.setAttribute('class', 'tab-content');
        container.appendChild(tabContent);

        var numPages = pdf.numPages;
        for (pageNumber = 1; pageNumber <= numPages; ++pageNumber) {
            var li = document.createElement('li');
            li.setAttribute('class', 'nav-item');
            var a = document.createElement('a');
            a.setAttribute('class', 'nav-link');
            a.setAttribute('href', '#pdfjs-page-' + pageNumber);
            if (pageNumber == 1) {
                a.classList.add('active');
            }
            a.appendChild(document.createTextNode('Page ' + pageNumber));
            a.setAttribute('role', 'tab');
            a.setAttribute('data-toggle', 'tab');
            li.appendChild(a);
            ul.appendChild(li);

            pdf.getPage(pageNumber).then(function(page) {
                var pageIndex = page._pageIndex + 1; // index commence à 0
                console.log(pageIndex);
                var viewport = page.getViewport({scale: 1});
                // Prepare canvas using PDF page dimensions
                var canvas = document.createElement('canvas');
                canvas.setAttribute('id', 'pdfjs-page-' + pageIndex);
                canvas.setAttribute('class', 'tab-pane fade');
                canvas.setAttribute('role', 'tabpanel');
                if (pageIndex == 1) {
                    canvas.classList.add('show', 'active');
                }
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                canvas = tabContent.appendChild(canvas);
                // Render PDF page into canvas context
                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
                var renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                    //console.log('Page ' + pageIndex + ' rendered');
                });
            });
        }
    }, function (reason) {
        // PDF loading error
        console.error(reason);
    });
}
