let Accordion = {
    init : () => {
        $d.off('click', '.accordion-title').on('click', '.accordion-title', function(e){
            let content = $(this).attr('data-content');
            if ($('.accordion-content[data-content="'+content+'"]').hasClass('accordion-content-visible')) {
                $(this).removeClass('accordion-title-open');
                $('.accordion-content[data-content="'+content+'"]').removeClass('accordion-content-visible');
            } else {
                $(this).addClass('accordion-title-open');
                $('.accordion-content[data-content="'+content+'"]').addClass('accordion-content-visible');
            }
        });
    }
}
module.exports = Accordion;