let AdminOfferShow = {

    init : () => {
        
        let Offer = require('./Offer');

        Offer.Map();
        Offer.OnLoad();
        Offer.Lots();
        AdminOfferShow.Actions();
    },

    FieldPresentationText : () => {
        if ($('#offer_presentation_text').length) {
            $('#offer_presentation_text').css('height', 'auto');
            $('#offer_presentation_text').height($('#offer_presentation_text')[0].scrollHeight+'px');
        }
    },

    Actions : () => {

        $d.off('change', '#admin_offer_state').on('change', '#admin_offer_state', function(){

            let value = $(this).val();

            if (value === 'closed' && $('#closing_message_container').length) {
                $('#closing_message_container').show();
                $('#closing_message')
                    .attr('required', 'required')
                    .prop('required', true)
                ;
            }
            if (value === 'published' && $('#closing_message_container').length) {
                $('#closing_message_container').hide();
                $('#closing_message')
                    .removeAttr('required')
                    .prop('required', false)
                ;
            }
        });

        $d.off('keyup', '#offer_presentation_text').on('keyup', '#offer_presentation_text', function(){
            AdminOfferShow.FieldPresentationText();
        });
    }
}
module.exports = AdminOfferShow;