/**
 * require element with id="date_end"  data-attribute="2022-02-18 15:15:00"
 * require container with id="countdown_view"
 */
let Countdown = {

    Init : () => {

        if ($('#countdown_view').length && $('#date_end').length) {

            let dateEnd = $('#date_end').attr('data-end');

            var deadline = new Date(dateEnd).getTime();
            var x = setInterval(function() {
                var now = new Date().getTime();
                var t = deadline - now;
                var days = Math.floor(t / (1000 * 60 * 60 * 24));
                var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
                var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((t % (1000 * 60)) / 1000);

                $("#countdown_view").html(days + "j " + hours + "h " + minutes + "m " + seconds + "s ");

                if (t < 0) {
                    clearInterval(x);
                    $("#countdown_view").html("EXPIRED").addClass('dateend-is-expired');
                    if ($('.item-link').length) $('.item-link').children('a.response-lot-btn').text('EXPIRED').removeAttr('href');
                }
            }, 1000);
        }
    }
}
module.exports = Countdown;