let AdminSidebar = {

    init : () => {
        
        let url         = window.location.href;
        let path        = url.replace(window.location.origin, '');
        let arrayPath   = (path.split('/')).filter(elm => elm);

        $('#_sidebar').find('a').each(function(index, item){

            let link            = $(item).attr('href');
            let arrayLink       = (link.split('/')).filter(elm => elm);
            let intersection    = arrayPath.filter(x => arrayLink.includes(x));

            if (intersection.length && intersection.length > 1) {

                let $ul = $(item).parent('.item-list-children').parent('._sidebar-item-list-children');
                let i = $ul.attr('data-index');

                if ($('._sidebar-item-list-children[data-index="'+i+'"]').length) {
                    $('._sidebar-item-list-children[data-index="'+i+'"]').addClass('_sidebar-item-list-children-active');
                    $('.item-list-parent[data-index="'+i+'"]').children('.item-list-parent-child-icon').html('<i class="fas fa-minus"></i>');
                }
            }
        });

        $d.off('mouseenter', '.item-list-parent').on('mouseenter', '.item-list-parent', function(){

            let index = $(this).attr('data-index');

            $('._sidebar-item-list-children').removeClass('_sidebar-item-list-children-active');

            $('.item-list-parent').children('.item-list-parent-child-icon').html('<i class="fas fa-plus"></i>');

            if ($('._sidebar-item-list-children[data-index="'+index+'"]').length) {
                $('._sidebar-item-list-children[data-index="'+index+'"]').addClass('_sidebar-item-list-children-active');
                $('.item-list-parent[data-index="'+index+'"]').children('.item-list-parent-child-icon').html('<i class="fas fa-minus"></i>');
            }
        });
    }
}
module.exports = AdminSidebar;