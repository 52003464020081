document.getElementById('select-primecontractor').addEventListener('change', selectDocumentList);

function selectDocumentList(event)
{
    let selectPrimecontractor = event;
    if (event instanceof Event) {
        selectPrimecontractor = this;
    }
    let selectedOption = selectPrimecontractor.selectedOptions[0];
    let documentLists = selectedOption.dataset.documentLists;
    let selectDocumentList = document.getElementById('select-document_list');
    selectDocumentList.textContent = '';
    if (undefined != documentLists) {
        documentLists = JSON.parse(documentLists);
        Object.keys(documentLists).forEach(function(key) {
            option = document.createElement('option');
            option.value = documentLists[key];
            option.text = key;
            selectDocumentList.appendChild(option);
        });
    }
}
// DOMContentLoaded
selectDocumentList(document.getElementById('select-primecontractor'));
