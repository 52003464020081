let OfferResponse = {

    init : () => {
        OfferResponse.FileInformation();
        OfferResponse.Actions();
    },

    Actions : () => {

        //response_state

        if ( $('select[name="response_state"]').length) {
            let stateValue = $('select[name="response_state"]').val();
            $('select[name="response_state"]')
                .removeAttr('class')
                .addClass('form-control offer-status-'+stateValue)
            ;

            let publishedText = $('#offer_response_btn').attr('published-text');
            let drafText = $('#offer_response_btn').attr('draft-text');

            if (stateValue === 'published') {
                $('#offer_response_btn').text( publishedText );
            } else {
                $('#offer_response_btn').text( drafText );
            }
        }

        $d.off('change', 'select[name="response_state"]').on('change', 'select[name="response_state"]', function(){

            let value = $(this).val();
            $(this)
                .removeAttr('class')
                .addClass('form-control offer-status-'+value)
            ;

            //offer-as-digital-signature

            if (!$('#offer_response_btn').hasClass('offer-as-digital-signature')) {

                let publishedText = $('#offer_response_btn').attr('published-text');
                let drafText = $('#offer_response_btn').attr('draft-text');

                if (value === 'published') {
                    $('#offer_response_btn').text( publishedText );
                } else {
                    $('#offer_response_btn').text( drafText );
                }
            }
        });

        $d.off('click', '.offer-upload-response-style').on('click', '.offer-upload-response-style', function(){

            $(this)
                .parent('.form-group-offer-upload-response')
                .children('.form-control-offer-upload-response')
                .trigger('click')
            ;
        });

        $d.off('change', '.form-control-offer-upload-response').on('change', '.form-control-offer-upload-response', function(){

            let value = $(this).val();
            let files = this.files;

            if (files && files.length) {

                /*$(this)
                    .parent('.form-group-offer-upload-response')
                    .children('.offer-upload-response-style')
                    .children('.offer-upload-response-style-text')
                    .html('');
                ;*/

                if ($(this).parent('.form-group-offer-upload-response').children('.offer-upload-response-style').children('.offer-upload-response-style-text-append').length) {

                    $(this)
                        .parent('.form-group-offer-upload-response')
                        .children('.offer-upload-response-style')
                        .children('.offer-upload-response-style-text-append')
                        .remove();
                    ;
                }

                for (var i = 0; i < files.length; i++) {

                    console.log(files[i])
                    let filename = files[i].name
                        filename = filename.replace(/C:\\fakepath\\/, '');

                    $(this)
                        .parent('.form-group-offer-upload-response')
                        .children('.offer-upload-response-style')
                        .append( '<div class="offer-upload-response-style-text offer-upload-response-style-text-append">'+filename+'</div>' )
                    ;
                    $(this)
                        .parent('.form-group-offer-upload-response')
                        .children('.offer-upload-response-style')
                        .addClass('offer-upload-response-style-with-file')
                    ;

                }
            }

            /*if (value.length) {

                $(this)
                    .parent('.form-group-offer-description-of-the-commercial-proposal')
                    .children('.offer-description-of-the-commercial-proposal-style')
                    .children('.offer-description-of-the-commercial-proposal-style-text')
                    .text( (value).replace(/C:\\fakepath\\/, '') )
                ;
                $(this)
                    .parent('.form-group-offer-description-of-the-commercial-proposal')
                    .children('.offer-description-of-the-commercial-proposal-style')
                    .addClass('offer-description-of-the-commercial-proposal-style-with-file')
                ;
            }*/
        });

        $d.off('click', '.offer-description-of-the-commercial-proposal-style').on('click', '.offer-description-of-the-commercial-proposal-style', function(){

            $(this)
                .parent('.form-group-offer-description-of-the-commercial-proposal')
                .children('.form-control-offer-description-of-the-commercial-proposal')
                .trigger('click')
            ;
        });

        $d.off('change', '.form-control-offer-description-of-the-commercial-proposal').on('change', '.form-control-offer-description-of-the-commercial-proposal', function(){

            let value = $(this).val();

            if (value.length) {

                $(this)
                    .parent('.form-group-offer-description-of-the-commercial-proposal')
                    .children('.offer-description-of-the-commercial-proposal-style')
                    .children('.offer-description-of-the-commercial-proposal-style-text')
                    .text( (value).replace(/C:\\fakepath\\/, '') )
                ;
                $(this)
                    .parent('.form-group-offer-description-of-the-commercial-proposal')
                    .children('.offer-description-of-the-commercial-proposal-style')
                    .addClass('offer-description-of-the-commercial-proposal-style-with-file')
                ;
            }
        });

        // >> *** OFFER UPLOAD RESPONSE ACTIONS ***
        $d.off('click', '.offer-upload-response-list-item-trash').on('click', '.offer-upload-response-list-item-trash', function(){

            let $parent = $(this).parent('.offer-upload-response-list-item');

            if ($parent.children('.offer-upload-response-list-item-trash-confirm-container').is(':visible')) {

                $('.offer-upload-response-list-item-trash-confirm-container').hide();

            } else {

                $('.offer-upload-response-list-item-trash-confirm-container').hide();
                $parent
                    .children('.offer-upload-response-list-item-trash-confirm-container')
                    .show()
                ;
            }            
        });
        $d.off('click', '.offer-upload-response-item-trash-cancel').on('click', '.offer-upload-response-item-trash-cancel', function(){
            $('.offer-upload-response-list-item-trash-confirm-container').hide();
        });
        // << ***
    },

    FileInformation : () => {

        let Offer = require('./Offer');

        $d
        .off('change', '#offer_information_sheet_to_be_completed_by_the_subcontractor_completed')
        .on('change', '#offer_information_sheet_to_be_completed_by_the_subcontractor_completed', function(e) {

            let $Jthis = $(this);
            let maxFile = $('.information-completed-by-subcontractor-file').length;
            let files = this.files;
            let validExt = $Jthis.attr('valid-ext') ? $Jthis.attr('valid-ext') : null;
            let response_id = $Jthis.attr('response-id') ? $Jthis.attr('response-id') : '0';
            let offer_id = $Jthis.attr('offer-id') ? $Jthis.attr('offer-id') : '0';

            $('#offer_information_sheet_to_be_completed_by_the_subcontractor_notice').html('');

            if (files && files.length && files.length > maxFile) {
                let maxFilesText = $('#offer_information_sheet_to_be_completed_by_the_subcontractor_notice').attr('max-files-notice');
                $('#offer_information_sheet_to_be_completed_by_the_subcontractor_notice')
                    .html('<small>'+maxFilesText+' '+maxFile+'</span>')
                ;
                return;
            }

            if (files && files.length && files.length < maxFile) {
                let minFilesText = $('#offer_information_sheet_to_be_completed_by_the_subcontractor_notice').attr('min-files-notice');
                $('#offer_information_sheet_to_be_completed_by_the_subcontractor_notice')
                    .html('<small>'+minFilesText+' '+maxFile+'</span>')
                ;
                return;
            }

            // TODO : check ext

            if (files && files.length && files.length === maxFile) {

                /*for (var i = 0; i < files.length; i++) {

                    let filename = files[i].name;
                    let ext = filename.split('.');
                        ext = ext[ext.length -1];
                        ext = ext.toLowerCase();

                    if (validExt === ext) {

                        OfferResponse.AjaxUploadFile(
                            files[i], 
                            (i + 1), 
                            'offer_information_sheet_to_be_completed_by_the_subcontractor', 
                            validExt, 
                            response_id,
                            offer_id, 
                        function(result){
                            if (result && result.response === 'success') {
                                window.location.reload();
                            }
                        });
                    }
                }*/

                OfferResponse.AjaxUploadFileInformation(
                    files, 
                    'offer_information_sheet_to_be_completed_by_the_subcontractor', 
                    validExt, 
                    response_id,
                    offer_id, 
                function(result){
                    if (result && result.response === 'success') {
                        window.location.reload();
                    }
                });
            }

            /*let maxFile = $('.information-completed-by-subcontractor-file').length;

            console.log(maxFile);

            if ($('.files-completed-upload').length) $('.files-completed-upload').remove();

            if ($('.response-information-sheet-to-be-completed-by-the-subcontractor-file-upload').length) {

                let $fileContainer = $('.response-information-sheet-to-be-completed-by-the-subcontractor-file-upload');

                let files = e.target.files
                if (files && files.length) {

                    if (files.length > maxFile) {
                        $('#response_file_information_modal').show();
                        $('#response_file_information_modal')
                            .find('.response-file-information-modal')
                            .find('.text')
                            .find('span')
                            .text( maxFile )
                        ;
                        $(this).val('');
                        return;
                    } else {

                        for (var i=0; i < files.length; i++) {
                            $fileContainer
                                .append('<div class="files-completed-upload">'+files[i].name+'</div>')
                            ;
                        }
                    }
                }
            }*/
        });

        $d.off('click', '#response_file_information_modal').on('click', '#response_file_information_modal', function(){
            $(this).hide();
            return;
        });
    },

    AjaxUploadFileInformation: (files, field_name, validExt, response_id, offer_id, callback) => {

        if (files) {

            let formData = new FormData();   

            for (var i = 0; i < files.length; i++) {
                formData.append("ajax_file_upload[]", files[i]);
            }
            
            formData.append("field_name", field_name);
            formData.append("valid_ext", validExt);
            formData.append("response_id", response_id);
            formData.append("offer_id", offer_id);

            let Ajax = $.ajax({
                url: '/offer/response/ajax/upload',
                type: 'POST',
                data: formData,
                contentType: false,
                processData: false,
            });
            Ajax.done(function(result){
                return callback( result );
            });
            Ajax.fail(function(error){
                return callback( error );
            });

        } else {
            return callback( false );
        }
    },

    BeforeSendModal : () => {

        let openModal = false;

        if ($('input[name="offer_digital_signature_files[]"]').length) {
            openModal = true;
        }
        if (openModal && $('#response_before_send_modal').length) {
            $('#response_before_send_modal').show();
        }
    }
}
module.exports = OfferResponse;