let Faq = {
    Init : () => {
        Faq.Actions();
    },

    Actions : () => {

        $d.off('click', '.faq-view-answer-btn').on('click', '.faq-view-answer-btn', function(){

            let $parent = $(this).parent('.faq-item-question');
            let $answer = $parent.parent('.faq-item').children('.faq-answer');

            if ($answer.is(':visible')) {
                $answer.slideUp(200);
                $parent.children('.faq-view-answer-btn').removeClass('faq-view-answer-btn-open')
            } else {
                $('.faq-answer').slideUp(200);
                $('.faq-view-answer-btn').removeClass('faq-view-answer-btn-open')
                $answer.delay(200).slideDown(200);
                $parent.children('.faq-view-answer-btn').addClass('faq-view-answer-btn-open')
            }
        });


        $d.off('keyup', 'input[name="faq_search"]').on('keyup', 'input[name="faq_search"]', function(){

            let keywords = $(this).val();
                keywords = keywords.toUpperCase();

            $('.faq-item').hide();

            if (keywords.length > 2) {

                $('.faq-item').each(function(index, item){

                    let text = $(item).text();
                        text = text.toUpperCase();

                    if (text.indexOf(keywords) != -1) $(item).show();
                });
            } else {
                $('.faq-item').show();
            }
        });
    }
}
module.exports = Faq;