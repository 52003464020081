let AdminParams = {
    init: () => {

    },
    getAll : (callback) => {
        let Ajax = $.ajax({
            url: '/admin/ajax/params/get/all',
            method: 'POST',
            dataType: 'JSON',
            data: JSON.stringify({ get_params: '1'})
        });
        Ajax.done(function(result){ return callback(result); });
        Ajax.fail(function(error){ return callback(error); });
    },
    getId : (callback) => {
        AdminParams.getAll(function(data){ return callback(data.id); });
    },
    getAdminEmails : (callback) => {
        AdminParams.getAll(function(data){ return callback(data.admin_emails); });
    },
    getContactEmails : (callback) => {
        AdminParams.getAll(function(data){ return callback(data.contact_emails); });
    },
    getLogNotification : (callback) => {
        AdminParams.getAll(function(data){ return callback(data.log_notification); });
    },
    getMaintenanceActive : (callback) => {
        AdminParams.getAll(function(data){ return callback(data.maintenance_active); });
    },
    getMaintenanceIps : (callback) => {
        AdminParams.getAll(function(data){ return callback(data.maintenance_ips); });
    },
    getAppLocaleSwitch : (callback) => {
        AdminParams.getAll(function(data){ return callback(data.app_locale_switch); });
    },
    getMapboxToken : (callback) => {
        AdminParams.getAll(function(data){ return callback(data.mapbox_token); });
    },

    AfterUpdate : (data,result) => {
        if (data && result) {
            if (result.response == "success") {
                if (data.switch_locale) {
                    window.location.reload();
                    return;
                }
            }
        }
    },

    AjaxUpdate : ( data ) => {
        //require('./FlashNotice').Clean();
        let Ajax = $.ajax({
            url : data.url,
            method: 'POST',
            dataType: 'JSON',
            data : JSON.stringify(data)
        });
        Ajax.done(function(result){
            //require('./FlashNotice').Show(result.response, result.message);
            AdminParams.AfterUpdate(data, result);
        });
        Ajax.fail(function(error){
            //require('./FlashNotice').Show('error', 'Error - '+error.status+' - '+error.statusText);
            AdminParams.AfterUpdate(data, error);
        });
    }
}
module.exports = AdminParams;
