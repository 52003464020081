let AdminOfferConfigEdit = {

    init : () => {
        AdminOfferConfigEdit.Map();
        AdminOfferConfigEdit.Move();
        AdminOfferConfigEdit.Action();
        AdminOfferConfigEdit.TabActions();
        AdminOfferConfigEdit.FormDuplicate();
        AdminOfferConfigEdit.FormsCommonActions('candidature');
        AdminOfferConfigEdit.FormsCommonActions('others');

        AdminOfferConfigEdit.FormsCommonActions('simplifie');
        AdminOfferConfigEdit.FormsCommonActions('detaille');
        AdminOfferConfigEdit.FormsCommonActions('consultation');
        AdminOfferConfigEdit.FormActions();
        AdminOfferConfigEdit.FormFields();
        AdminOfferConfigEdit.FullForm();
    },

    FullForm : () => {

        require('jquery-ui-dist/jquery-ui');

        $( '.form-full-type-list' ).sortable({
            items: "> .form-full-type-container",
            scroll: true,
            handle: '.type-position',
            containment: "parent",
            sort: function(event, ui) {

            },
            stop: function( event, ui ) {
                //console.log('*** STOP '+parentId+' ***')
                console.log(ui)

                let itemLength = $('.form-full-type-container').length;
                let count = 0;
                let itemPositionList = [];

                $('.form-full-type-container').each(function(index, item) {
                    
                    let position = index + 1;
                    
                    $(item)
                        .children('.form-full-type-name')
                        .children('.type-position')
                        .children('.position-number')
                        .text( position )
                    ;
                    count++;

                    itemPositionList.push({
                        uid : $(item).attr('data-uid'),
                        position : position
                    })

                    if (count === itemLength) {

                        AdminOfferConfigEdit.AjaxPositionForm(itemPositionList, function(result){
                        })
                    }
                });
            },
            update: function( event, ui ) {

            }
        });
    },

    Map : () => {

        if ($('#offer_map').length) {

            require('./Map').init('offer_map');
        }
    },

    TabActions : () => {

        // >> Tab navigation actions
        $d.off('click', '.nav-tab').on('click', '.nav-tab', function(){

            let view = $(this).attr('data-view');

            $('.nav-tab').removeClass('nav-tab-active');
            $('.offer-edit-nav-tab-view').removeClass('offer-edit-nav-tab-view-active');

            $('#offer_form_candidature_load').html('');
            $('#offer_form_candidature_select').prop('selectedIndex', 0);

            $('#offer_form_others_load').html('');
            $('#offer_form_others_select').prop('selectedIndex', 0);

            $('#offer_form_simplifie_load').html('');
            $('#offer_form_simplifie_select').prop('selectedIndex', 0);

            $('#offer_form_detaille_load').html('');
            $('#offer_form_detaille_select').prop('selectedIndex', 0);

            $('#offer_form_consultation_load').html('');
            $('#offer_form_consultation_select').prop('selectedIndex', 0);

            $(this).addClass('nav-tab-active');

            $('.offer-edit-nav-tab-view[data-view="'+view+'"]')
                .addClass('offer-edit-nav-tab-view-active')
            ;
            window.location.hash = view;

            AdminOfferConfigEdit.FieldPresentationText();
        });

        // >> Check if hash and clic tab view
        let currentHash = window.location.hash;
        if (currentHash) {
            $('.nav-tab[data-view="'+(currentHash.replace('#', ''))+'"]').trigger('click');
        }
    },

    FormActions : () => {

        // >> --- Remove form ---
        $d.off('click', '#config_form_name_remove').on('click', '#config_form_name_remove', function(){
            $(this).hide();
            $('#config_form_remove').show();
        });

        $d.off('click', '.remove-btn-cancel').on('click', '.remove-btn-cancel', function(){
            $('#config_form_remove').hide();
            $('#config_form_name_remove').show();
        });

        $d.off('click', '#config_form_remove_confirm').on('click', '#config_form_remove_confirm', function(){

            $('#global_loader').removeClass('global-loader-hidden');
            $('#config_form_remove').trigger('submit');
        });
        // <<

        // >> --- Active or inactive template ---
        $d.off('change', '#offer_template_is_active').on('change', '#offer_template_is_active', function(){

            let isActive = $(this).prop('checked');
            let uid = $(this).val()

            AdminOfferConfigEdit.AjaxActiveTemplate(uid, isActive, function(result){

                if (result && result.response == 'success') {

                    let activeText = $('.template-is-active-text').attr('active-text');
                    let inactiveText = $('.template-is-active-text').attr('inactive-text');

                    if (isActive) {
                        $('.template-is-active-text').text( activeText );
                    } else {
                        $('.template-is-active-text').text( inactiveText );
                    }
                }
            });
        });
        // <<

        // >> --- Active or inactive form ---
        $d.off('change', '#offer_form_is_active').on('change', '#offer_form_is_active', function(){

            let isActive = $(this).prop('checked');

            AdminOfferConfigEdit.AjaxActiveForm(isActive, function(result){

                if (result && result.response == 'success') {

                    let activeText = $('.form-is-active-text').attr('active-text');
                    let inactiveText = $('.form-is-active-text').attr('inactive-text');

                    if (isActive) {
                        $('.form-is-active-text').text( activeText );
                    } else {
                        $('.form-is-active-text').text( inactiveText );
                    }
                }
            });
        });
        // <<
    },

    FormDuplicate : () => {

        $d.off('change', '#offer_duplicate_form_select_all').on('change', '#offer_duplicate_form_select_all', function(){

            let checked = $(this).prop('checked');
            $('input[name="offer_duplicate_form[]"]').prop('checked', checked);

            if ($('input[name="offer_duplicate_seasonality_month"]').length) {
                $('input[name="offer_duplicate_seasonality_month"]').prop('checked', checked);
            }
            if ($('input[name="offer_duplicate_seasonality_day"]').length) {
                $('input[name="offer_duplicate_seasonality_day"]').prop('checked', checked);
            }
        });

        $d.off('change', '#offer_duplicate_to_society_select_all').on('change', '#offer_duplicate_to_society_select_all', function(){

            let checked = $(this).prop('checked');
            $('input[name="offer_duplicate_form_to_society[]"].input-is-visible').prop('checked', checked);
        });

        // >> Search society in duplicate section
        $d.off('keyup', '#offer_duplicate_form_to_society_search').on('keyup', '#offer_duplicate_form_to_society_search', function(){

            let value = ($(this).val()).toUpperCase();

            if (value.length > 2) {

                $('.duplicate-society-item')
                    .hide()
                    .find('input[name="offer_duplicate_form_to_society[]"]')
                    .removeClass('input-is-visible')
                ;

                let itemLength = $('.duplicate-society-item').length, count = 0;

                $('.duplicate-society-item').each(function(index, item){

                    let text = ($(item).text()).toUpperCase();
                    if (text.indexOf(value) != -1) {
                        $(item)
                            .show()
                            .find('input[name="offer_duplicate_form_to_society[]"]')
                            .addClass('input-is-visible')
                        ;
                    }
                    count ++;
                    if (count === itemLength) {
                        $('.offer-duplicate-society-search-result').text($('.input-is-visible').length);
                    }
                });
            } else {
                $('.duplicate-society-item')
                    .show()
                    .find('input[name="offer_duplicate_form_to_society[]"]')
                    .addClass('input-is-visible')
                ;
                $('.offer-duplicate-society-search-result').text('');
            }
        });
    },

    FieldPresentationText : () => {
        if ($('#offer_presentation_text').length) {
            $('#offer_presentation_text').css('height', 'auto');
            $('#offer_presentation_text').height($('#offer_presentation_text')[0].scrollHeight+'px');
        }
    },

    FormFields : () => {

        $d.off('keyup', '#offer_presentation_text').on('keyup', '#offer_presentation_text', function(){
            AdminOfferConfigEdit.FieldPresentationText();
        });

        $d.off('change', 'textarea[name="offer_presentation_text"]').on('change', 'textarea[name="offer_presentation_text"]', function(){

            let parentId = '#'+$(this).parent().parent().parent().attr('id');

            AdminOfferConfigEdit.BeforeLoopData(parentId);
        });

        $d.off('change', 'input[name="offer_field_required"]').on('change', 'input[name="offer_field_required"]', function(){

            let parentId = '#'+$(this).parent().parent().parent().attr('id');

            AdminOfferConfigEdit.BeforeLoopData(parentId);
        });

        $d.off('change', 'input[name="offer_digital_signature"]').on('change', 'input[name="offer_digital_signature"]', function(){

            let parentId = '#'+$(this).parent().parent().parent().attr('id');
            let checked = $(this).prop('checked');

            /*if ( checked ) {

                let $files = $(this)
                    .parent('.offer-field-digital-signature-required')
                    .parent('.offer-field-container')
                    .find('.form-group > .offer-field-files-linked > .offer-field-file-linked-item')
                ;
                let fileLength = $files.length;
                let count = 0;
                
                $files.each(function(index, item){

                    let filename = $(item).find('.linked-item-name').text();
                        filename = filename.trim();

                    let filenamesplit = filename.split('.');
                    let ext = filenamesplit[(filenamesplit.length - 1)];
                        ext = ext.toLowerCase();

                    if (ext != 'pdf') {
                        $(item).remove();
                    }
                    count++;

                    if (count === fileLength) {
                        AdminOfferConfigEdit.BeforeLoopData(parentId);
                    }
                });
            }*/

            AdminOfferConfigEdit.BeforeLoopData(parentId);
        });

        $d.off('change', 'input[name="offer_digital_signature_before"]').on('change', 'input[name="offer_digital_signature_before"]', function(){

            let parentId = '#'+$(this).parent().parent().parent().attr('id');

            AdminOfferConfigEdit.BeforeLoopData(parentId);
        });

        $d.off('change', '.offer-field-files-link').on('change', '.offer-field-files-link', function(e){

            let files = this.files;
            let $Jthis = $(this)
            let id = $Jthis.attr('id');
            let type = id.split('-')[0];
            let count = 0;
            let validExt = $Jthis.attr('valid-ext') ? $Jthis.attr('valid-ext') : null;

            if (files && files.length) {

                for (var i= 0; i < files.length; i++) {

                    let $parent = $Jthis.parent('label').parent('.offer-field-required-file').parent('.offer-field-container').find('.offer-field-files-linked');

                    let index = $parent.children('.offer-field-file-linked-item').length;
                    let number = index+1;

                    $parent.append(`
                        <div data-index="${number}" class="offer-field-file-linked-item">
                            <div class="linked-item-name"><a href="#">${type}_${files[i].name}</a></div>
                            <div class="linked-item-trash"><i class="fas fa-trash"></i></div>
                        </div>
                    `);
                    count ++;

                    if ($('.offer-field-not-files-linked').length) {
                        $('.offer-field-not-files-linked').remove();
                    }

                    if (count === files.length) {
                        AdminOfferConfigEdit.BeforeUpLoad($parent, files[i], id, number, validExt, true);
                    } else {
                        AdminOfferConfigEdit.BeforeUpLoad($parent, files[i], id, number, validExt, false);
                    }
                }
            }
            $Jthis.val('');
            files = null;
            id = null;
            type = null;
            validExt = null;
        });

        // >> Remove file linked to field
        $d.off('click', '.linked-item-trash').on('click', '.linked-item-trash', function(){

            let $this = $(this);
            let filename = $this.parent().children('.linked-item-name').text();
                filename = filename.trim();
                filename = filename.replaceAll(' ', '');
            let parentId = '#'+$this.parent().parent().parent().parent().parent().attr('id');
            
            $this.parent('.offer-field-file-linked-item').remove();

            AdminOfferConfigEdit.BeforeLoopData(parentId);
            AdminOfferConfigEdit.AjaxRemoveFile(filename, function(result){
                console.log(result)
                if (result && result.response === 'success') {
                    //window.location.reload();
                }
            });
        });

        // >> Search field in available fields col
        $d.off('keyup', 'input[name="offer_field_container_search"]').on('keyup', 'input[name="offer_field_container_search"]', function(){
            
            let value = ($(this).val()).toUpperCase();
            let $container = $(this).parent().parent().parent().find('.offer-fields-container');

            if (value.length > 2) {

                $container.find('.offer-field-container').hide();

                $container.find('.offer-field-container').each(function(index, item){

                    let text = ($(item).text()).toUpperCase();
                    if (text.indexOf(value) != -1) {
                        $(item).show();
                    }
                });
            } else {
                $container.find('.offer-field-container').show();
            }
        });

        // >> Reset search field
        if ($('input[name="offer_field_container_search"]').length) {
            $('input[name="offer_field_container_search"]').val('');
        }

        $d.off('mouseenter', 'span.label-text').on('mouseenter', 'span.label-text', function(e){

            let $this =$(this);
            let text = $this.text();

            AdminOfferConfigEdit.LabelReset(function(result){

                $this
                    .parent('label')
                    .attr('label-text', text)
                ;
                $this
                    .parent('label')
                    .prepend('<input type="text" name="field_label_update" value="'+text+'" />')
                ;
                $this.remove();
            });
        });

        $d.off('mouseleave', 'input[name="field_label_update"]').on('mouseleave', 'input[name="field_label_update"]', function(){

            let $this =$(this);
            let oldtext = $this.parent('label').attr('label-text');
            let text = $this.val();
            let parentId = '#'+$this.parent().parent().parent().parent().attr('id');

            AdminOfferConfigEdit.LabelReset(function(result){

                if (text != oldtext && parentId) {
                    AdminOfferConfigEdit.BeforeLoopData(parentId);
                }
            });
        });


         $d.off('change', 'input[name="forfait_variable"]').on('change', 'input[name="forfait_variable"]', function(){

            let checked = $(this).prop('checked');

            $('.field-lot-config-last-forfait-variable')
                .children('.config-last-item')
                .find('input[type="checkbox"]')
                .prop('checked', checked)
        });

        $d.off('change', 'input[name="variable"]').on('change', 'input[name="variable"]', function(){

            let checked = $(this).prop('checked');

            $('.field-lot-config-last-variable')
                .children('.config-last-item')
                .find('input[type="checkbox"]')
                .prop('checked', checked)
            ;
        });

         $d.off('click', '.field-lot-config-update-btn').on('click', '.field-lot-config-update-btn', function(){
            
            let parentId = '#'+$(this).parent().parent().parent().attr('id');

            AdminOfferConfigEdit.BeforeLoopData(parentId);
        });
    },

    Action : () => {

        // >> Refresh page on click
        $d.off('click', '.offer-config-refresh').on('click', '.offer-config-refresh', function(){
            window.location.reload();
        });

        $d.off('change', '.seasonality-item-field > input[type="number"]').on('change', '.seasonality-item-field > input[type="number"]', function(){

            let value = $(this).val();

            if (value == '') {
                $(this).val('0');
            }

            if (parseInt(value) < 0) {
                $(this).val('0');
            }

            if (parseInt(value) > 100) {
                $(this).val('100');
            }
        });
    },

    FormsCommonActions : (type) => {

        require('jquery-ui-dist/jquery-ui');

        $d.off('submit', '#offer_form_'+type+'_add').on('submit', '#offer_form_'+type+'_add', function(e){

            e.preventDefault();

            let formName = $('#offer_form_'+type+'_add_name').val();

            if (formName.length > 1) {

                $('#global_loader').removeClass('global-loader-hidden');

                $('#offer_form_'+type+'_add_name').val('');

                AdminOfferConfigEdit.AjaxCreateForm(formName, type, function(result){

                    if (result && result.response === 'success') {

                        if (result.data) {
                            $('#offer_form_'+type+'_select')
                                .children('option')
                                .removeAttr('selected')
                            ;
                            $('#offer_form_'+type+'_select')
                                .append('<option value="'+result.data+'" selected>'+formName+'</option>')
                            ;
                            $('#offer_form_'+type+'_select').trigger('change');

                            $('#offer_form_'+type+'_count_template').children('span').text( $('#offer_form_'+type+'_select').children('option').length - 1 );
                        }
                        //$('#global_loader').addClass('global-loader-hidden');
                    }
                });
                setTimeout(function() { $('#global_loader').addClass('global-loader-hidden'); }, 1500);
            }
        });

        $d.off('change', '#offer_'+type+'_name').on('change', '#offer_'+type+'_name', function(){

            let formName = $(this).val();
            let uid = $(this).parent().children('#offer_config_form_uid').val();

            if (formName.length > 1) {

                $('#global_loader').removeClass('global-loader-hidden');

                AdminOfferConfigEdit.AjaxFormNameUpdate(formName, uid, function(result){

                    if (result && result.response === 'success') {

                        $('#offer_form_'+type+'_select')
                            .children('option[value="'+uid+'"]')
                            .text(formName)
                        ;
                        $('#offer_form_'+type+'_select').trigger('change');
                    }
                    /*if (result && result.response === 'warning') {

                        $('.config-form-name-field').children('small').text(result.message).css('color', 'red');
                        setTimeout( function(){
                            $('#offer_form_'+type+'_select').trigger('change');
                        }, 2000);
                    }*/
                    //$('#global_loader').addClass('global-loader-hidden');
                });
                setTimeout(function() { $('#global_loader').addClass('global-loader-hidden'); }, 1500);
            }
        })

        $d.off('change', '#offer_form_'+type+'_select').on('change', '#offer_form_'+type+'_select', function(){

            let value = $(this).val();

            $('#offer_form_'+type+'_load').html('');

            if (value.length > 1) {

                $('#global_loader').removeClass('global-loader-hidden');

                AdminOfferConfigEdit.AjaxLoadForm(value, function(result){
                    if (result) {
                        $('#offer_form_'+type+'_load').html(result);
                        AdminOfferConfigEdit.Sort("#offer_fields_container_"+type, "#offer_"+type+"_dropzone_left, #offer_"+type+"_dropzone_right");
                        AdminOfferConfigEdit.Sort("#offer_"+type+"_dropzone_left", "#offer_fields_container_"+type+", #offer_"+type+"_dropzone_right");
                        AdminOfferConfigEdit.Sort("#offer_"+type+"_dropzone_right", "#offer_fields_container_"+type+", #offer_"+type+"_dropzone_left");
                    }
                    //$('#global_loader').addClass('global-loader-hidden');
                });

                setTimeout(function() { $('#global_loader').addClass('global-loader-hidden'); }, 1500);
            }
        });
    },

    AjaxPositionForm : (data, callback) => {

        let Ajax = $.ajax({
            url: '/admin/offer/position/form/ajax',
            method: 'POST',
            data: {
                offer_form_position : data
            },
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });

    },

    AjaxActiveTemplate : (uid, active=false, callback) => {

        let Ajax = $.ajax({
            url: '/admin/offer/active/template/ajax',
            method: 'POST',
            data: {
                offer_template_is_active : active,
                offer_config_form_uid : uid,
                society_id: $('#offer_edit_society_id').val(),
                offer_template_active_post : 1
            },
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },

    AjaxActiveForm : (active=false, callback) => {

        let Ajax = $.ajax({
            url: '/admin/offer/active/form/ajax',
            method: 'POST',
            data: {
                offer_form_is_active : active,
                society_id: $('#offer_edit_society_id').val(),
                offer_form_active_post : 1
            },
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },

    AjaxFormNameUpdate : (formName, uid, callback) => {

        let Ajax = $.ajax({
            url: '/admin/offer/update/formname/ajax',
            method: 'POST',
            data: {
                offer_config_form_uid : uid,
                offer_config_form_name: formName,
                offer_edit_society_id: $('#offer_edit_society_id').val()
            },
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },

    AjaxLoadForm : (uid, callback) => {

        let Ajax = $.ajax({
            url: '/admin/offer/load/form/ajax',
            method: 'POST',
            data: {
                offer_config_form_uid : uid,
                offer_edit_society_id: $('#offer_edit_society_id').val()
            },
            dataType: 'HTML'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },

    AjaxCreateForm: (formName, formType, callback) => {

        let Ajax = $.ajax({
            url: '/admin/offer/create/form/ajax',
            method: 'POST',
            data: {
                offer_config_form_name : formName,
                offer_config_form_category : formType,
                offer_edit_society_id: $('#offer_edit_society_id').val()
            },
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },

    LabelReset : (callback) => {

        let itemLength = $('input[name="field_label_update"]').length;
        let count = 0;

        if (itemLength) {

            $('input[name="field_label_update"]').each(function(index, item) {

                let oldtext = $(item).parent('label').attr('label-text');
                let text = $(item).val();

                if (text.length) {

                    $(item)
                        .parent('label')
                        .prepend('<span class="label-text">'+text+'</span>')
                    ;
                } else {

                    $(item)
                        .parent('label')
                        .prepend('<span class="label-text">'+oldtext+'</span>')
                    ;
                }
                $(item).remove();

                count++;

                if (count === itemLength) {
                    return callback(true);
                }
            });
        } else {
            return callback(true);
        }
    },

    BeforeLoopData : (parentId) => {

        if (parentId.indexOf('dropzone') != -1) {

            $('#offer_edit_save_in_progress').show();

            // >> LOOP DATA AND UPDATE DATABASE
            AdminOfferConfigEdit.LoopData(parentId, function(data) {

                let offer_config_form = parentId.replace('#', '').replace('_dropzone', '');
                let offer_config_form_type = offer_config_form.replace('_left','').replace('_right','');
                let offer_config_form_name = $('#'+offer_config_form_type+'_name').length ? $('#'+offer_config_form_type+'_name').val() : 'default';
                let offer_config_form_uid = $('#offer_config_form_uid').length ? $('#offer_config_form_uid').val() : '';

                // >> UPDATE DATABASE
                AdminOfferConfigEdit.AjaxPost({
                    offer_config_form: offer_config_form,
                    offer_config_form_name : offer_config_form_name,
                    offer_config_form_uid : offer_config_form_uid,
                    offer_config_form_data : data,
                    offer_edit_society_id: $('#offer_edit_society_id').val(),
                }, function(result){
                    if (result && result.response && result.response === 'success') {

                        $(parentId).addClass('ajax-return-success');

                        setTimeout(function(){
                            $(parentId).removeClass('ajax-return-success');
                        }, 500);
                    }
                    $('#offer_edit_save_in_progress').hide();
                });
            });
        }
    },

    BeforeUpLoad : ($parent, file, id, i, validExt, last=false) => {

        if ($('#error_message').length) $('#error_message').remove();
        
        AdminOfferConfigEdit.AjaxUploadFile(file, id, i, validExt, function(result){

            if (result && result.response === "success" && result.data) {

                $parent 
                    .find('.offer-field-file-linked-item[data-index="'+result.data.file_index+'"]')
                    .find('.linked-item-name')
                    .addClass('linked-item-name-uploaded')
                ;

                $parent 
                    .find('.offer-field-file-linked-item[data-index="'+result.data.file_index+'"]')
                    .find('.linked-item-name-uploaded')
                    .html('<a href="#">'+result.data.filename+'</a>')
                ;
            } else if (result && result.response === 'error') {
                $parent
                    .prepend('<div id="error_message" class="error-message">'+result.message+'</div>')
                ;

                if (result.data && result.data.file_index) {
                    $parent
                        .find('.offer-field-file-linked-item[data-index="'+result.data.file_index+'"]')
                        .find('.linked-item-trash')
                        .trigger('click')
                    ;
                }
            }

            if (last) {

                let parentId = '#'+$parent.parent().parent().parent().attr('id');

                AdminOfferConfigEdit.BeforeLoopData(parentId);
            }
        });
    },

    AjaxRemoveFile : (filename, callback) => {

        if (filename) {

            let Ajax = $.ajax({
                url: '/admin/offer/ajax/remove/file',
                type: 'POST',
                data: {
                    file_name: filename.trim(),
                    ajax_remove_file : 1
                },
            });
            Ajax.done(function(result){
                return callback( result );
            });
            Ajax.fail(function(error){
                return callback( error );
            });

        } else {
            return callback( false );
        }
    },

    AjaxUploadFile: (file, fieldId, index, validExt, callback) => {

        if (file) {

            let formData = new FormData();       
            
            formData.append("ajax_file_upload", file);
            formData.append("field_id", fieldId);
            formData.append("file_index", index);
            formData.append("valid_ext", validExt);

            let Ajax = $.ajax({
                url: '/admin/offer/ajax/upload',
                type: 'POST',
                data: formData,
                contentType: false,
                processData: false,
            });
            Ajax.done(function(result){
                return callback( result );
            });
            Ajax.fail(function(error){
                return callback( error );
            });

        } else {
            return callback( false );
        }
    },

    Move : () => {

        require('jquery-ui-dist/jquery-ui');

        AdminOfferConfigEdit.ActiveFormView();

        // >> --- FORM ---
        AdminOfferConfigEdit.Sort("#offer_fields_container_form", "#offer_form_dropzone_left, #offer_form_dropzone_right");
        AdminOfferConfigEdit.Sort("#offer_form_dropzone_left", "#offer_fields_container_form, #offer_form_dropzone_right");
        AdminOfferConfigEdit.Sort("#offer_form_dropzone_right", "#offer_fields_container_form, #offer_form_dropzone_left");
        // <<
    },

    Sort : (parentId, ConnectWithIds) => {

        require('jquery-ui-dist/jquery-ui');

        var currentlyScrolling = false;
        var SCROLL_AREA_HEIGHT = 40; // Distance from window's top and bottom edge.

        $( parentId ).sortable({
            items: "> .offer-field-container",
            connectWith: ConnectWithIds,
            scroll: true,
            handle: '.offer-field-grip',
            sort: function(event, ui) {

                if (currentlyScrolling) {
                  return;
                }

                var windowHeight   = $(window).height();
                var mouseYPosition = event.clientY;

                if (mouseYPosition < SCROLL_AREA_HEIGHT) {

                  currentlyScrolling = true;

                  $('html, body').animate({
                    scrollTop: "-=" + windowHeight / 2 + "px" // Scroll up half of window height.
                  }, 
                  400, // 400ms animation.
                  function() {
                    currentlyScrolling = false;
                  });

                } else if (mouseYPosition > (windowHeight - SCROLL_AREA_HEIGHT)) {

                  currentlyScrolling = true;

                  $('html, body').animate({
                    scrollTop: "+=" + windowHeight / 2 + "px" // Scroll down half of window height.
                  }, 
                  400, // 400ms animation.
                  function() {
                    currentlyScrolling = false;
                  });
                }
            },
            stop: function( event, ui ) {
                AdminOfferConfigEdit.ActiveFormView();
            },
            update: function( event, ui ) {

                AdminOfferConfigEdit.SortPosition(parentId, function(result){

                    AdminOfferConfigEdit.BeforeLoopData(parentId);
                });

                let count = 0;

                if ($(parentId+'_count').length) {

                    count = $(parentId).children('.offer-field-container').length;

                    $(parentId+'_count').children('.notice-count').text( count );
                }
            }
        });

    },

    ActiveFormView : () => {

        if (!$('.offer-form-is-active').hasClass('offer-form-is-active-hidden')) {
            $('.offer-form-is-active').addClass('offer-form-is-active-hidden');
        }

        if (!$('#global_loader').hasClass('global-loader-hidden')) {
            $('#global_loader').addClass('global-loader-hidden');
        }

        if (!$('#offer_form_dropzone_left').find('.offer-field-container').length && !$('#offer_form_dropzone_right').find('.offer-field-container').length) {

            let isActive = false;

            AdminOfferConfigEdit.AjaxActiveForm(isActive, function(result){

                if (result && result.response == 'success') {

                    let activeText = $('.form-is-active-text').attr('active-text');
                    let inactiveText = $('.form-is-active-text').attr('inactive-text');

                    if (isActive) {
                        $('.form-is-active-text').text( activeText );
                        $('#offer_form_is_active').prop('checked', true).attr('checked', 'checked');
                    } else {
                        $('.form-is-active-text').text( inactiveText );
                        $('#offer_form_is_active').prop('checked', false).removeAttr('checked');
                    }
                }
            });

        } else {
            $('.offer-form-is-active').removeClass('offer-form-is-active-hidden');
        }
    },

    LoopData : (parentId, callback) => {

        let data = [];
        let itemLength = $(parentId).children('.offer-field-container').length;
        let count = 0;

        if (itemLength === 0) return callback(data);

        setTimeout(function(){

            $(parentId).children('.offer-field-container').each(function(index, item){

                AdminOfferConfigEdit.LoopFile(item, function(files){

                    let required = $(item).find('.offer-field-required').children('input[name="offer_field_required"]').prop('checked');

                    if (required) {

                        if (!$(item).find('label').children('span.required').length) {

                            $(item)
                                .find('label')
                                .append('<span class="required">*</span>')
                            ;
                        }
                    } else {

                        if ($(item).find('label').children('span.required').length) {

                            $(item)
                                .find('label')
                                .children('span.required')
                                .remove()
                            ;
                        }
                    }

                    let forfait = false;
                    let forfait_variable = false;
                    let forfait_variable_options = [];
                    let variable = false;
                    let variable_options = [];

                    let $lotConfigEl = $(item).children('.offer-field-lot-config');
                    let $forfaitVariableEl = $lotConfigEl.find('.field-lot-config-last-forfait-variable');
                    let $variableEl = $lotConfigEl.find('.field-lot-config-last-variable');

                    if ($(item).children('.offer-field-lot-config').length) {

                        forfait = $lotConfigEl.find('.config-first-item-forfait').find('input[name="forfait"]').prop('checked');
                        forfait_variable = $lotConfigEl.find('.config-first-item-forfait-variable').find('input[name="forfait_variable"]').prop('checked');
                        variable = $lotConfigEl.find('.config-first-item-variable').find('input[name="variable"]').prop('checked');

                        forfait_variable_options = {
                            points: $forfaitVariableEl.find('input[name="forfait_variable_points"]').prop('checked'),
                            colis: $forfaitVariableEl.find('input[name="forfait_variable_colis"]').prop('checked'),
                            positions: $forfaitVariableEl.find('input[name="forfait_variable_positions"]').prop('checked'),
                            km: $forfaitVariableEl.find('input[name="forfait_variable_km"]').prop('checked'),
                            kg: $forfaitVariableEl.find('input[name="forfait_variable_kg"]').prop('checked')
                        }

                        variable_options = {
                            points: $variableEl.find('input[name="variable_points"]').prop('checked'),
                            colis: $variableEl.find('input[name="variable_colis"]').prop('checked'),
                            positions: $variableEl.find('input[name="variable_positions"]').prop('checked'),
                            km: $variableEl.find('input[name="variable_km"]').prop('checked'),
                            kg: $variableEl.find('input[name="variable_kg"]').prop('checked')
                        }
                    }

                    let field_name = $(item).attr('id').replace('_field', '');
                    let value = '';

                    if (field_name === 'offer_presentation_text') {
                        value = $(item).find('.form-group').find('#offer_presentation_text').val();
                    }

                    data.push({
                        field: field_name,
                        field_title : $(item).find('.form-group').find('label').children('span.label-text').text(),
                        position: $(item).find('.offer-field-position').text(),
                        required : required,
                        digital_signature : $(item).find('.offer-field-digital-signature-required').children('input[name="offer_digital_signature"]').prop('checked'),
                        digital_signature_before : $(item).find('.offer-field-digital-signature-required-before').children('input[name="offer_digital_signature_before"]').prop('checked'),
                        number : $(item).find('.offer-field-number').text(),
                        files : files.length ? files : false,
                        lot_config: {
                            forfait: forfait,
                            forfait_variable : forfait_variable,
                            variable : variable,
                            forfait_variable_options: forfait_variable_options,
                            variable_options: variable_options
                        },
                        value : value
                    });

                    count++;

                    if (count === itemLength) {

                        return callback(data);
                    }

                });
            });
        }, 1500);
    },

    LoopFile : (element, callback) => {
        
        let files = [];
        let elementLength = $(element).find('.offer-field-files-linked').find('.linked-item-name-uploaded').length;
        let count = 0;

        if (elementLength) {

            $(element).find('.offer-field-files-linked').find('.linked-item-name-uploaded').each(function(index, item){
                files.push(($(item).text()).trim());
                count++;
                if (count === elementLength) {
                    return callback(files);
                }
            });
        } else {
            return callback(files);
        }
    },

    SortPosition : (parentId, callback) => {

        let itemLength = $(parentId).children('.offer-field-container').length;
        let count = 0;

        if (itemLength === 0) return callback(itemLength);

        $(parentId).children('.offer-field-container').each(function(index, item){
            $(item).find('.offer-field-position').html( index + 1 );
            count++;
            if (count === itemLength) {
                return callback(itemLength);
            }
        });
    },

    AjaxPost : (data, callback) => {

        let Ajax = $.ajax({
            url: '/admin/offer/update/form/ajax',
            method: 'POST',
            data: data,
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    }
}
module.exports = AdminOfferConfigEdit;