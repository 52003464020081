let Appointment = {

    init : () => {

        console.log('*** APPOINTMENT ***')
        Appointment.Edit();
    },

    Edit : () => {

        $d.off('click', '.modal-test-close').on('click', '.modal-test-close', function(){

            if ($('.modal-test').length) $('.modal-test').remove();
            return;
        });

        $d.off('click', '.event-edit').on('click', '.event-edit', function(e){

            e.preventDefault();
            let URL = $(this).attr('href');
            if (URL.length > 1) Appointment.ModalEditAjax(URL);
        });
    },

    ModalEditAjax : (url) => {

        let Ajax = $.ajax({
            url : url,
            method : 'GET',
            dataType: "HTML"
        });

        Ajax.done(function(html){

            if ($('.modal-test').length) $('.modal-test').remove();
            $('body').append(html);
            return;
        })

        Ajax.fail(function(error){
            console.log(error);
        });
    }
}
module.exports = Appointment;