let Log = {
    init: () => {
        Log.View();
        Log.NotificationActive();
        Log.Delete();
    },
    View: () => {
        if ($('.log-item').length) {
            $d.off('click', '.log-title-open-content').on('click', '.log-title-open-content', function(){
                $('.log-title').removeClass('log-title-open');
                if ($(this).parent().parent().find('.log-content').is(':visible')) {
                    $(this).parent().parent().find('.log-content').slideUp(300);
                } else {
                    $('.log-content').slideUp(300);
                    $(this).parent().parent().find('.log-content').slideDown(300);
                    $(this).parent().addClass('log-title-open');
                }
            });
        }
    },

    Delete : () => {
        if ($('.log-delete').length) {
            $d.off('click', '.log-delete').on('click', '.log-delete', function(e){
                e.preventDefault();
                let link = $(this).attr('href');
                $('.modal-confirm').fadeIn(300);
                $d.off('click', '.modal-btn-confirm').on('click', '.modal-btn-confirm', function(){
                    window.location = link;
                });
            });
        }
    },

    NotificationActive : () => {
        if ($('input[name="params_logs_notification"]').length) {
            $d.off('change', 'input[name="params_logs_notification"]').on('change', 'input[name="params_logs_notification"]', function(){
                let data = {
                    url : $(this).attr('ajax-url'),
                    checked: $(this).prop('checked') ? 1 : 0
                }
                require('./AdminParams').AjaxUpdate(data);
                return;
            });
        }
    }
}
module.exports = Log;
