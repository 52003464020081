let Forum = {
    msnry : false,
    Init : () => {
        Forum.MasonryLayout();
        Forum.Add();
        Forum.Edit();
        Forum.Search();
        Forum.Move();
    },

    MasonryLayout : () => {

        if ($('body').attr('id') === 'public_market_forum') {

            let Masonry = require('masonry-layout');

            Forum.msnry = new Masonry( '.forum-messages-items', {
                itemSelector: '.forum-message',
                columnWidth: '.forum-message-sizer',
                gutter: 10
            });
        }
    },

    MasonryReload : () => {
        Forum.msnry.reloadItems();
        Forum.msnry.destroy();
        Forum.MasonryLayout();

    },

    AjaxPosition : (position) => {

        let Ajax = $.ajax({
            url : '/publicmarket/forum/ajax/position',
            method: 'POST',
            data: {
                'forum_update_position' : '1',
                'position' : position
            },
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            console.log(result);
        });

        Ajax.fail(function(error){
            console.log(error);
        });
    },

    Move : () => {

        require('jquery-ui-dist/jquery-ui');

        $( "#forum_messages_items" ).sortable({
            items: ".forum-message",
            handle: ".forum-message-move-option",
            //placeholder: "forum-message-placeholder",
            //containment: "parent",
            start: function(e, ui){

                Forum.MasonryReload();
            },
            change : function(e, ui) {

                Forum.MasonryReload();
            },
            stop( event, ui ) {

                Forum.MasonryReload();

                let position = [];

                let itemLength = $('.forum-message').length, count = 0;

                $('.forum-message').each(function(index, item){

                    $(item).find('.item-position-number').text(index);

                    position.push($(item).attr('data-id'));

                    count++;

                    if (count === itemLength) {
                        Forum.AjaxPosition(position);
                    }
                });

            }
        });

    },

    Search : () => {

        $d.off('keydown keyup keypress', '#forum_search').on('keydown keyup keypress', '#forum_search', function(){

            let value = $(this).val();
                value = value.toLocaleLowerCase();

            if ($('.form-message').length) {

                $('.forum-message').children('.form-message').hide();
                $('.forum-message').children('.message').show();
            }

            $('.forum-message').hide();

            if (value.length > 1) {

                $('.forum-message').each(function(index, item){

                    let text = $(item).children('.message').text();
                        text = text.toLocaleLowerCase();

                    if (text.indexOf(value) != -1) {
                        $(item).show();
                        $('#search_count').text($('.forum-message:visible').length);
                        Forum.MasonryReload();
                    }
                });
            } else {
                $('.forum-message').show();
                $('#search_count').text($('.forum-message:visible').length);
                Forum.MasonryReload();
            }
        });

    },

    Edit : () => {

        $d.off('click', '.forum-message-edit-option').on('click', '.forum-message-edit-option', function(){

            let $container = $(this).parent().parent('.forum-message-options').parent('.forum-message');

            if ($container.children('.form-message').is(':visible')) {

                $container.children('.form-message').hide();
                $container.children('.message').show();
                Forum.MasonryReload();

            } else {
                $container.children('.message').hide();
                $container.children('.form-message').show();
                Forum.MasonryReload();
            }
        });

        $d.off('click', '.message-trash-cancel').on('click', '.message-trash-cancel', function(){
            $(this).parent('.message-trash').parent('.message-trash-container').hide();
        });

        $d.off('click', '.message-option-trash').on('click', '.message-option-trash', function(){
            let id = $(this).attr('data-id');
            $('.message-trash-container[data-id="'+id+'"]').show();
        });
    },

    Add : () => {

        $d.off('click', '.forum-message-add').on('click', '.forum-message-add', function(){
            $('.forum-form-modal').show();
        });

        $d.off('click', '.forum-modal-close').on('click', '.forum-modal-close', function(){
            $('.forum-form-modal').hide();
        });
    },

    Active : () => {

        $d.off('mouseenter', '.message-active-view').on('mouseenter', '.message-active-view', function(){

            let text = $(this).attr('data-reverse')
            $(this).html('<i class="far fa-times-circle"></i> ' + text);
        });

        $d.off('mouseleave', '.message-active-view').on('mouseleave', '.message-active-view', function(){

            let text = $(this).attr('data-text')
            $(this).html('<i class="far fa-check-circle"></i> ' + text);
        });


        $d.off('mouseenter', '.message-inactive-view').on('mouseenter', '.message-inactive-view', function(){

            let text = $(this).attr('data-reverse')
            $(this).html('<i class="far fa-check-circle"></i> ' + text);
        });

        $d.off('mouseleave', '.message-inactive-view').on('mouseleave', '.message-inactive-view', function(){

            let text = $(this).attr('data-text')
            $(this).html('<i class="far fa-times-circle"></i> ' + text);
        });
    }
}
module.exports = Forum;