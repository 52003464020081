let Email = {

    init : () => {
        Email.Actions();
        Email.NavTab();
        Email.CustomDate();
        Email.TemplateModalView();

        console.log('*** Email ***')
    },

    TemplateModalView : () => {

        $d.off('click', '.email-template-view').on('click', '.email-template-view', function(e){
            e.preventDefault();
            let templateId = $(this).attr('template-id');
            $('.modal-email-template[template-id="'+templateId+'"]').fadeIn(100);
            return;
        });

        $d.off('click', '.modal-template-header-close').on('click', '.modal-template-header-close', function(){
            $(this).parent().parent('.modal-email-template').fadeOut(100);
            return;
        });
    },

    NavTab : () => {
        $d.off('click', '.items-label-icon').on('click', '.items-label-icon', function(){
            $('.template-item-container').removeClass('template-item-container-visible');
            $('.label-icon-btn').removeClass('label-icon-btn-open');
            $(this).find('.label-icon-btn').addClass('label-icon-btn-open');
            $(this).parent().parent().find('.template-item-container').addClass('template-item-container-visible');
            Email.EmailOptionReset();
            return;
        });

        $d.off('change', '.email-template-period-field').on('change', '.email-template-period-field', function(){
            let $this = $(this), value = $(this).val();
            console.log(value);
            Email.EmailOptionsDays($this, value);
        });
    },

    EmailOptionsDays : ($this, value) => {

        let $parent = $this.parent().parent().parent().parent();

        if (value === 'one_by_day') {
            $parent.find('.template-email-list-container').hide();
        } else if (value === 'one_by_month') {

            $parent.find('.template-email-list-container').hide();
            $parent.find('.template-email-list-container[data-list="days"]').show();

        } else if (value === 'one_by_year') {

            $parent.find('.template-email-list-container').hide();
            $parent.find('.template-email-list-container[data-list="month"]').show();
        } else {
            $parent.find('.template-email-list-container').hide();
        }
    },

    EmailOptionReset : () => {
        $('.form-email-template-period').find('input').each(function(index, item){
            if (!$(item).hasClass('email-form-options')) $(item).prop('checked', false);
        });
        $('.template-item-custom-date').find('input').val('');
        $('.item-custom-date-is-removable').remove();
    },

    Actions : () => {

        $d.off('click', '.modal-email-template-close').on('click', '.modal-email-template-close', function(){
            $(this).parent().hide();
            return;
        });

        $d.off('click', '.email-template-period-options').on('click', '.email-template-period-options', function(){
            let templateId = $(this).parent().parent().parent().attr('template-id');

            if ($('.form-email-template-period[template-id="'+templateId+'"]').is(':visible')) {
                $('.form-email-template-period[template-id="'+templateId+'"]').slideUp(100);
            } else {
                $('.form-email-template-period').hide();
                $('.form-email-template-period[template-id="'+templateId+'"]').slideDown(100);
            }
            return;
        });

        $d.off('change', 'input[name="email_template"]').on('change', 'input[name="email_template"]', function(){
            let checked = $(this).prop('checked'), templateId = $(this).parent().parent().parent().parent().attr('template-id');
            if (checked) {
                if ($('.form-email-template-period[template-id="'+templateId+'"]').is(':visible')) {
                    $('.form-email-template-period[template-id="'+templateId+'"]').slideUp(100);
                } else {
                    $('.form-email-template-period').hide();
                    $('.form-email-template-period[template-id="'+templateId+'"]').slideDown(100);
                }
            } else {
                $('.form-email-template-period').slideUp(); 
            }
            return;
        });

        $d.off('click', '.email-add-new-template').on('click', '.email-add-new-template', function(){
            if ($('.form-add-email-template').is(':visible')) {
                $('.form-add-email-template').slideUp(100);
            } else {
                $('.form-add-email-template').slideDown(100);
            }
        });
    },

    CustomDate : () => {
        $d.off('click', '.item-add-date-btn').on('click', '.item-add-date-btn', function(){
            let inputName = $(this).parent().parent().find('input[type="date"]').attr('name')
            let html = 
            `<div class="template-item-flex template-item-custom-date item-custom-date-is-removable">
                <div class="item-flex">
                    <input type="date" name="${inputName}" />
                </div>
                <div class="item-flex template-item-remove-date"><i class="fas fa-minus"></i></div>
            </div>`;
            $(this).parent().parent().prepend(html);
            return;
        });

        $d.off('click', '.template-item-remove-date').on('click', '.template-item-remove-date', function(){
            $(this).parent().remove();
            return;
        });
    }
}
module.exports = Email;