require('leaflet');
let Map = {

    initialized : false,

    init : (mapId) => {

        if (!Map.initialized) {

            let map = L.map(mapId, {
                editable: true,
                scrollWheelZoom: false,
            });

            map.setView([43.296482, 5.36978], 6);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; AndCo'
            }).addTo(map);

            if ($('textarea[name="offer_departments"]').length) {
                Map.Departments(map, 'offer_departments');
            }
            if ($('textarea[name="offer_town"]').length) {
                Map.Town(map, 'offer_town');
            }
            if ($('textarea[name="offer_cities"]').length) {
                Map.Cities(map, 'offer_cities');
            }
            Map.initialized = true;
        }
    },

    Cities : (map, citiesField) => {
        //https://leafletjs.com/examples/choropleth/
        //https://nominatim.openstreetmap.org/search.php?q=aix%20en%20provence&polygon_geojson=1&format=jsonv2

        let cities = $('textarea[name="'+citiesField+'"]').val();
            cities = Map.ReplaceValue(cities);

        Map.CitiesLoad(map, cities);

        $d.off('keypress', 'textarea[name="'+citiesField+'"]').on('keypress', 'textarea[name="'+citiesField+'"]', function(e){
            if (e.keyCode === 13) {
                $(':focus').trigger('blur');
                return false;
            }
        });

        $d.off('change', 'textarea[name="'+citiesField+'"]').on('change', 'textarea[name="'+citiesField+'"]', function(){

            cities = $(this).val();
            cities = Map.ReplaceValue(cities);
            cities = cities.split(' ').join('-');

            if (citiesField === 'offer_cities') {

                citiesSplit = cities.split(',');
                
                if (citiesSplit.length) {

                    for (var i=0; i < citiesSplit.length; i++) {
                        citiesSplit[i] = citiesSplit[i].charAt(0).toUpperCase() + citiesSplit[i].slice(1);
                    }
                    cities = citiesSplit.join();
                }
            }

            $(this).val(cities);

            Map.LayerCitiesRemove(map);

            Map.CitiesLoad(map, cities);
        });
    },

    LayerCitiesRemove: (map) => {

        map.eachLayer( function(layer) {
            if ( layer.cities &&  layer.cities === "cities") {
                map.removeLayer(layer)
            }
        });
        $('.population-legend').remove();
    },

    CitiesLoad : (map, cities) => {

        //https://github.com/gregoiredavid/france-geojson

        function getColor(d) {
            return d > 1000 ? '#800026' :
                   d > 500  ? '#BD0026' :
                   d > 200  ? '#E31A1C' :
                   d > 100  ? '#FC4E2A' :
                   d > 50   ? '#FD8D3C' :
                   d > 20   ? '#FEB24C' :
                   d > 10   ? '#FED976' :
                              '#FFEDA0';
        }

        function style(feature) {
            return {
                fillColor: getColor(feature.properties.density),
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.7
            };
        }

        cities = Map.ReplaceValue(cities);

        let arrayCities = cities.split(',');

        if (arrayCities && cities.length && arrayCities.length) {

            if ($('#offer_cities_postcode').length) {
                $('#offer_cities_postcode').val('');
            }

            for (var i = 0; i < arrayCities.length; i++) {

                let search = arrayCities[i];
                search = search.split(' ').join('-');

                $.get('https://nominatim.openstreetmap.org/search.php?q='+search+'&polygon_geojson=1&addressdetails=1&format=jsonv2', function(result){

                    if (result) {

                        let firstResult = result[0];

                        L.geoJson(firstResult.geojson, {
                            style: style,
                            onEachFeature: function(feature, layer) {
                                layer.cities = "cities"
                            }
                        }).addTo(map);

                        Map.LayerBoundZoom(map);

                        if ($('#offer_cities_postcode').length) {
                            let offer_cities_postcode = $('#offer_cities_postcode').val();

                            if (firstResult.address && firstResult.address['ISO3166-2-lvl6']) {

                                let newValue = offer_cities_postcode;
                                let split = firstResult.address['ISO3166-2-lvl6'].split('-');
                                if (split.length > 0) {
                                    if (!isNaN(parseInt(split[1]))) {
                                        newValue = (offer_cities_postcode != '' ? (offer_cities_postcode.length < 2 ? '0'+offer_cities_postcode : offer_cities_postcode)+',' : '') +parseInt(split[1]);
                                    }
                                }
                                $('#offer_cities_postcode').val(newValue)
                            }
                        }
                    }
                })
            }   
        }
        if (!$('.population-legend').length) {

            let legend = L.control({position: 'bottomright'});

            legend.onAdd = function (map) {

                var div = L.DomUtil.create('div', 'info legend population-legend'),
                    grades = [0, 10, 20, 50, 100, 200, 500, 1000],
                    labels = [];

                div.innerHTML += 'Population m<sup>2</sup><br><br>';

                for (var i = 0; i < grades.length; i++) {
                    div.innerHTML += '<i style="background:' + getColor(grades[i] + 1) + '"></i> ' +grades[i] + (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+');
                }

                div.innerHTML += '<br><br>';

                return div;
            };

            legend.addTo(map);
        }
    },

    TownLoad : (map, town) => {

        //https://github.com/gregoiredavid/france-geojson

        function getColor(d) {
            return d > 1000 ? '#800026' :
                   d > 500  ? '#BD0026' :
                   d > 200  ? '#E31A1C' :
                   d > 100  ? '#FC4E2A' :
                   d > 50   ? '#FD8D3C' :
                   d > 20   ? '#FEB24C' :
                   d > 10   ? '#FED976' :
                              '#FFEDA0';
        }

        function style(feature) {
            return {
                fillColor: getColor(feature.properties.density),
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.7
            };
        }

        town = Map.ReplaceValue(town);

        let arrayTown = town.split(',');

        $.get(window.location.origin+'/geojson/fr-postcode.geojson', function(result){

            if (result) {

                statesData = JSON.parse(result);

                for (var i = 0; i < statesData.features.length; i++) {

                    if (arrayTown.indexOf(statesData.features[i].properties.code) != -1) {
                        L.geoJson(statesData.features[i], {
                            style: style,
                            onEachFeature: function(feature, layer) {
                                layer.town = "town"
                            }
                        }).addTo(map);
                    }
                }
                Map.LayerBoundZoom(map);
            }
        });

        if (!$('.population-legend').length) {

            let legend = L.control({position: 'bottomright'});

            legend.onAdd = function (map) {

                var div = L.DomUtil.create('div', 'info legend population-legend'),
                    grades = [0, 10, 20, 50, 100, 200, 500, 1000],
                    labels = [];

                div.innerHTML += 'Population m<sup>2</sup><br><br>';

                for (var i = 0; i < grades.length; i++) {
                    div.innerHTML += '<i style="background:' + getColor(grades[i] + 1) + '"></i> ' +grades[i] + (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+');
                }

                div.innerHTML += '<br><br>';

                return div;
            };

            legend.addTo(map);
        }
    },

    LayerBoundZoom : (map)=> {

        var bounds = L.latLngBounds([]);
        map.eachLayer( function(layer) {
            if (layer.town && layer.town === "town") {
                var layerBounds = layer.getBounds();
                bounds.extend(layerBounds);
            }
            if (layer.department && layer.department === "department") {
                var layerBounds = layer.getBounds();
                bounds.extend(layerBounds);
            }
            if (layer.cities && layer.cities === "cities") {
                var layerBounds = layer.getBounds();
                bounds.extend(layerBounds);
            }
        });
        if (Object.keys(bounds).length) {
            map.fitBounds(bounds);
        }
    },

    LayerTownRemove: (map) => {

        map.eachLayer( function(layer) {
            if ( layer.town &&  layer.town === "town") {
                map.removeLayer(layer)
            }
        });
        $('.population-legend').remove();
    },

    Town : (map, townField) => {
        //https://leafletjs.com/examples/choropleth/

        let town = $('textarea[name="'+townField+'"]').val();
            town = Map.ReplaceValue(town);

        Map.TownLoad(map, town);

        $d.off('keypress', 'textarea[name="'+townField+'"]').on('keypress', 'textarea[name="'+townField+'"]', function(e){
            if (e.keyCode === 13) {
                $(':focus').trigger('blur');
                return false;
            }
        });

        $d.off('change', 'textarea[name="'+townField+'"]').on('change', 'textarea[name="'+townField+'"]', function(){

            town = $(this).val();
            town = Map.ReplaceValue(town);

            $(this).val(town);

            Map.LayerTownRemove(map);

            Map.TownLoad(map, town);
        });
    },

    DepartmentsLoad : (map, departments) => {

        //https://github.com/gregoiredavid/france-geojson

        function getColor(d) {
            return d > 1000 ? '#800026' :
                   d > 500  ? '#BD0026' :
                   d > 200  ? '#E31A1C' :
                   d > 100  ? '#FC4E2A' :
                   d > 50   ? '#FD8D3C' :
                   d > 20   ? '#FEB24C' :
                   d > 10   ? '#FED976' :
                              '#FFEDA0';
        }

        function style(feature) {
            return {
                fillColor: getColor(feature.properties.density),
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.7
            };
        }

        departments = Map.ReplaceValue(departments);

        let arrayDep = departments.split(',');

        $.get(window.location.origin+'/geojson/fr-departments-with-dom-tom.geojson', function(result){

            if (result) {

                statesData = JSON.parse(result);

                for (var i = 0; i < statesData.features.length; i++) {

                    if (arrayDep.indexOf(statesData.features[i].properties.code) != -1) {
                        L.geoJson(statesData.features[i], {
                            style: style,
                            onEachFeature: function(feature, layer) {
                                layer.department = "department"
                            }
                        }).addTo(map);
                    }
                }

                Map.LayerBoundZoom(map);
            }
        });

        if (!$('.population-legend').length) {

            let legend = L.control({position: 'bottomright'});

            legend.onAdd = function (map) {

                var div = L.DomUtil.create('div', 'info legend population-legend'),
                    grades = [0, 10, 20, 50, 100, 200, 500, 1000],
                    labels = [];

                div.innerHTML += 'Population m<sup>2</sup><br><br>';

                for (var i = 0; i < grades.length; i++) {
                    div.innerHTML += '<i style="background:' + getColor(grades[i] + 1) + '"></i> ' +grades[i] + (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+');
                }

                div.innerHTML += '<br><br>';

                return div;
            };

            legend.addTo(map);
        }
    },

    LayerDepartmentsRemove : (map) => {

        map.eachLayer( function(layer) {
            if ( layer.department &&  layer.department === "department") {
                map.removeLayer(layer)
            }
        });

        $('.population-legend').remove();
    },

    Departments : (map, departmentsField) => {

        //https://leafletjs.com/examples/choropleth/

        let departments = $('textarea[name="'+departmentsField+'"]').val();
            departments = Map.ReplaceValue(departments);

        Map.DepartmentsLoad(map, departments);

        $d.off('keypress', 'textarea[name="'+departmentsField+'"]').on('keypress', 'textarea[name="'+departmentsField+'"]', function(e){
            if (e.keyCode === 13) {
                $(':focus').trigger('blur');
                return false;
            }
        });

        $d.off('change', 'textarea[name="'+departmentsField+'"]').on('change', 'textarea[name="'+departmentsField+'"]', function(){

            departments = $(this).val();
            departments = Map.ReplaceValue(departments);

            $(this).val(departments);

            Map.LayerDepartmentsRemove(map);

            Map.DepartmentsLoad(map, departments);
        });
    },

    ReplaceValue : (str) => {
        str = str.toLowerCase();
        str = str.split(', ').join(',');
        str = str.split(' ,').join(',');
        return str;
    }
}
module.exports = Map;