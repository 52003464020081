let Search = {

    Init : (inputName, itemClass, start=1) => {

        console.log('*** SEARCH ***');

        let counter = 0, visible = 0;
        if ($('.search-counter').length) {
            counter = parseInt($('.search-counter').text().trim());
        }

        $d.off('change', 'input[name="'+inputName+'"]').on('change', 'input[name="'+inputName+'"]', function(){
            let value = $(this).val();value = value.toUpperCase().trim();
            if (value.length < start) {
                $(itemClass).show();
                if ($('.search-counter').length) $('.search-counter').text(counter);
                visible = 0;
            } else {
                $(itemClass).hide();
                $(itemClass).each(function(index, item){
                    let text = $(item).text();text = text.toLocaleUpperCase().trim();
                    if (text.indexOf(value) != -1) {
                        $(item).show();
                        visible = $(itemClass+':visible').length;
                        if ($('.search-counter').length) $('.search-counter').text(visible);
                    } else {

                    }
                });
            }
        });
    }
}
module.exports = Search;