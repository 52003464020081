let AjaxUpload = {

    Init : () => {
        AjaxUpload.FindField();
        AjaxUpload.Actions();
        AjaxUpload.OnChange();
    },

    Actions : () => {

        $d.off('click', '.ajax-upload-file-delete').on('click', '.ajax-upload-file-delete', function(e){

            e.preventDefault();

            let $that = $(this), objectName = $that.attr('object-name'), objectId = $that.attr('object-id'), filename = $that.attr('filename');

            AjaxUpload.Remove(filename, objectName, objectId, function(result){

                if (result && result.response === 'success') {

                    if ($that.parent('.ajax-upload-list-file').parent('.ajax-upload-list-files').children('.ajax-upload-list-file').length === 1) {
                        $that.parent('.ajax-upload-list-file').parent('.ajax-upload-list-files').find('.ajax-upload-label-tmp').remove();
                    }
                    $that.parent('.ajax-upload-list-file').remove();

                    let requiredElement = $(`#ajax_upload_is_required_${ objectName }_${ objectId }`);
                    if (requiredElement.length) {
                        let requiredDataValue = requiredElement.parent().find('#'+objectId).attr('data-value');
                        if (requiredDataValue && !requiredDataValue.length) {
                            requiredElement.val('');
                        }
                    }
                }
            });
        });
    },

    FindField : () => {

        // >> -- Modele de la structure --
        /*<div class="ajax-upload-wrapper">
            <div class="ajax-upload-label-text">Synthèse des lots en consultation<span class="ajax-upload-label-text-required">*</span></div>
            <label class="ajax-upload-label ajax-upload-label-locked" for="public_market_synthesis">
                <input 
                    type="file" 
                    id="public_market_synthesis" 
                    name="public_market[synthesis]" 
                    lang="fr" 
                    disabled="disabled" 
                    placeholder="uploads/public_market/1665675244505-63491cc9bb811.png" 
                    class="ajax-upload custom-file-input ajax-upload-initialized" 
                    object-name="public_market_synthesis" 
                    object-id="115" 
                    label-text="Synthèse des lots en consultation" 
                    dropzone-text="Cliquez pour remplacer le fichier" 
                    label-tmp="Fichier en attente de validation" 
                    data-value="uploads/public_market/1665676696657-6349141be7828.png" 
                    is-required="1"
                >
                <i class="fas fa-lock"></i>
            </label>
        </div>*/
        // <<

        if ($('.ajax-upload').length) {

            $('.ajax-upload').each(function(index, input){

                if (!$(input).hasClass('ajax-upload-initialized')) {

                    let objectName  = $(input).attr('object-name');
                    let objectId    = $(input).attr('object-id').length ? $(input).attr('object-id') : 0;
                    let multiple    = $(input).attr('multiple') ? true : false;
                    let zonetext    = $(input).attr('dropzone-text');
                    let labeltext   = $(input).attr('label-text');
                    let labeltmp    = $(input).attr('label-tmp');
                    let isDisabled  = $(input).prop('disabled');
                    let isReadonly  = $(input).prop('readonly');
                    let dataValue   = $(input).attr('data-value');
                    let isRequired  = $(input).attr('required') ? true : false;

                    if (!isRequired) {
                        isRequired  = $(input).attr('is-required') === '1' ? true : false;
                    }

                    if (objectName && objectId) {

                        $(input).addClass('ajax-upload-initialized');

                        let id = $(input).attr('id');

                        if (!id) {
                            id = 'ajax_upload_field_'+$(input).index();
                            $(input).attr('id', id)
                        }

                        $(`label[for="${ id }"]:not(.ajax-upload-label)` ).remove();

                        $(input).wrap(`<label class="ajax-upload-label" for="${ id }">`);

                        $(`.ajax-upload-label[for="${ id }"]`).wrap('<div class="ajax-upload-wrapper">');

                        if (labeltext) {

                            if (isRequired) {
                                labeltext += '<span class="ajax-upload-label-text-required">*</span>';
                            }
                            $(`<div class="ajax-upload-label-text">${ labeltext }</div>`).insertBefore($(`label.ajax-upload-label[for="${ id }"]`));
                        }

                        if (!isDisabled && !isReadonly) {

                            if (isRequired) {
                                $(`.ajax-upload-label[for="${ id }"]`).append(`
                                    <input type="hidden" class="ajax-upload-required" id="ajax_upload_is_required_${ objectName }_${ objectId }" name="ajax_upload_is_required_${ objectName }_${ objectId }" required="required" value="${ dataValue }" />
                                `)
                            }

                            $(input).parent('label.ajax-upload-label').append(`<i class="fas fa-upload"></i><span>${ zonetext }</span>`);
                            $(input).parent('label.ajax-upload-label').parent().append(`<ul id="ajax_upload_list_files_${ id }" class="ajax-upload-list-files"></ul>`);

                            $(`#ajax_upload_list_files_${ id }`).append('<li class="ajax-upload-list-file ajax-upload-list-file-loader"><i class="fas fa-spinner"></i></li>');

                            AjaxUpload.GetUploads(objectName, objectId, function(result){

                                $('#ajax_upload_list_files_'+id).find('.ajax-upload-list-file-loader').remove();

                                if (result && result.response === 'success' && result.data) {

                                    if ($(`#ajax_upload_list_files_${ id }`).find('.ajax-upload-label-tmp').length) {
                                        $(`#ajax_upload_list_files_${ id }`).find('.ajax-upload-label-tmp').remove();
                                    }
                                    $(`#ajax_upload_list_files_${ id }`).prepend(`<div class="ajax-upload-label-tmp">${ labeltmp }</div>`);

                                    $.each(result.data, function(index, filename){

                                        let uploadFile = 
                                        `<li class="ajax-upload-list-file">
                                            <div class="ajax-upload-file">${filename}</div>
                                            <a object-name="${ objectName }" object-id="${ objectId }" filename="${filename}" class="ajax-upload-file-delete" href="#"><i class="far fa-trash-alt"></i></a>
                                            <input class="ajax_${ objectName }" type="hidden" name="ajax_upload_files[${ objectName }][]" value="uploads/${ objectName }/${ objectId }/${ filename }"/>
                                        </li>`;

                                        $(`#ajax_upload_list_files_${ id }`).append(uploadFile);
                                    });
                                }
                            });
                        } else {
                            $(input).parent('label.ajax-upload-label').addClass('ajax-upload-label-locked').append('<i class="fas fa-lock"></i>');
                        }

                    } else {
                        alert('le champ ajax-upload a des attributs manquants, object-name et object-id');
                    }
                }
            });
        }
    },

    FileRemove : () => {

        if ($('.ajax-upload-label-file').length) {
            $('.ajax-upload-label-file').remove();
        }
    },

    OnChange : () => {

        $d.off('change', '.ajax-upload').on('change', '.ajax-upload', function(e) {

            let objectName  = $(this).attr('object-name'), objectId = $(this).attr('object-id').length ? $(this).attr('object-id') : 0;
            let multiple    = $(this).attr('multiple') ? true : false;
            let isRequired  = $(this).attr('required') ? true : false;
                isRequired  = $(this).attr('is-required') === '1' ? true : false;
            let labeltmp    = $(this).attr('label-tmp');

            let id = $(this).attr('id');

            if (!id) {
                id = 'ajax_upload_field_'+$(this).index();
                $(this).attr('id', id)
            }

            AjaxUpload.FileRemove();

            if ($(`#ajax_upload_list_files_${ id }`).find('.ajax-upload-label-tmp').length) {
                $(`#ajax_upload_list_files_${ id }`).find('.ajax-upload-label-tmp').remove();
            }

            let currentTarget = e.currentTarget, count = 0;

            if (currentTarget.files.length) {

                let files = currentTarget.files;

                $(`#ajax_upload_list_files_${ id }`).prepend(`<div class="ajax-upload-label-tmp">${ labeltmp }</div>`);

                for (var i=0; i < files.length; i++) {

                    let file = files[i], filename = file.name, filesize = file.size, filetype = file.type;

                    $(`#ajax_upload_list_files_${ id }`).append('<li class="ajax-upload-list-file ajax-upload-list-file-loader"><i class="fas fa-spinner"></i></li>');
                    
                    AjaxUpload.Upload(file, objectName, objectId, function(result){

                        if ($('.ajax-upload-file-invalid').length) $('.ajax-upload-file-invalid').remove();

                        if (result && result.response === 'success') {

                            if (result.data) {

                                if (!multiple && $(`.${ objectName }_current[object-id="${ objectId }"]`).length) {
                                    $(`.${ objectName }_current[object-id="${ objectId }"]`).remove();
                                }

                                if (!multiple && $(`#ajax_upload_list_files_${ id }`).find('.ajax-upload-file-delete').length) {

                                    $(`#ajax_upload_list_files_${ id }`).find('.ajax-upload-file-delete').each(function(index, file){
                                        $(file).trigger('click');
                                    });
                                }

                                let uploadFile = 
                                `<li class="ajax-upload-list-file">
                                    <div class="ajax-upload-file">${ result.data.filename }</div>
                                    <a object-name="${ objectName }" object-id="${ objectId }" filename="${ result.data.filename }" class="ajax-upload-file-delete" href="#"><i class="far fa-trash-alt"></i></a>
                                    <input class="ajax_${ objectName }" type="hidden" name="ajax_upload_files[${ objectName }][]" value="uploads/${ objectName }/${ objectId }/${ result.data.filename }"/>
                                </li>`;

                                $(`#ajax_upload_list_files_${ id }`).append( uploadFile );
                                $(`#ajax_upload_list_files_${ id }`).find('.ajax-upload-list-file-loader').remove();

                                let requiredElement = $(`#ajax_upload_is_required_${ objectName }_${ objectId }`);
                                if (requiredElement.length) {
                                    let requiredDataValue = requiredElement.parent().find('#'+objectId).attr('data-value');
                                    if (requiredDataValue && !requiredDataValue.length) {
                                        requiredElement.val('1');
                                    }
                                }
                            }
                            $(`#${ id }`).val('');
                        } else if(result && result.response === 'error') {

                            let uploadFile = 
                            `<li class="ajax-upload-list-file">
                                <div class="ajax-upload-file ajax-upload-file-invalid">${ result.message }</div>
                            </li>`;

                            $(`#ajax_upload_list_files_${ id }`).append( uploadFile );
                            $(`#ajax_upload_list_files_${ id }`).find('.ajax-upload-list-file-loader').remove();
                        }
                    });

                    count++;

                    if (count === files.length) {
                        AjaxUpload.FileRemove();
                    }
                }

            }
        });
    },

    GetUploads : (objectName, objectId, callback) => {

        if (objectName && objectId) {

            let formData = new FormData();       
            
            formData.append("object_name", objectName);
            formData.append("object_id", objectId);

            let Ajax = $.ajax({
                url: '/ajax/get/uploads',
                type: 'POST',
                data: formData,
                contentType: false,
                processData: false,
            });
            Ajax.done(function(result){
                return callback( result );
            });
            Ajax.fail(function(error){
                return callback( error );
            });

        } else {

            return callback( false );
        }

    },

    Upload : (file, objectName, objectId, callback) => {

        if (file && objectName && objectId) {

            let formData = new FormData();       
            
            formData.append("ajax_file_upload", file);
            formData.append("object_name", objectName);
            formData.append("object_id", objectId);

            let Ajax = $.ajax({
                url: '/ajax/upload',
                type: 'POST',
                data: formData,
                contentType: false,
                processData: false,
            });
            Ajax.done(function(result){
                return callback( result );
            });
            Ajax.fail(function(error){
                return callback( error );
            });

        } else {

            return callback( false );
        }
    },

    Remove : (filename, objectName, objectId, callback) => {

        if (filename && objectName && objectId) {

            let formData = new FormData();       
            
            formData.append("filename", filename);
            formData.append("object_name", objectName);
            formData.append("object_id", objectId);

            let Ajax = $.ajax({
                url: '/ajax/upload/remove',
                type: 'POST',
                data: formData,
                contentType: false,
                processData: false,
            });
            Ajax.done(function(result){
                return callback( result );
            });
            Ajax.fail(function(error){
                return callback( error );
            });

        } else {

            return callback( false );
        }

    },
}
module.exports = AjaxUpload;
