let Offer = {

    init : () => {
        Offer.Actions();
        Offer.OnLoad();
        Offer.Map();
        Offer.Upload();
        Offer.Specifications();
        Offer.Lots();
    },

    Map : () => {

        if ($('#offer_map').length) {
            require('./Map').init('offer_map');
        }
    },

    Specifications : () => {

        $d.off('click', '.offer-specifications-style').on('click', '.offer-specifications-style', function(){

            $(this)
                .parent('.form-group-offer-specifications')
                .children('.form-control-offer-specifications')
                .trigger('click')
            ;
        });

        /*$d.off('change', '#offer_information_sheet_to_be_completed_by_the_subcontractor_completed').on('change', '#offer_information_sheet_to_be_completed_by_the_subcontractor_completed', function(e){

            console.log(e);

            let $Jthis = $(this);
            let files = this.files;
            let validExt = $Jthis.attr('valid-ext') ? $Jthis.attr('valid-ext') : null;

            console.log(files)
            console.log(validExt);
        });*/

        $d.off('change', '.form-control-offer-specifications').on('change', '.form-control-offer-specifications', function(){

            let value = $(this).val();

            if (value.length) {

                $(this)
                    .parent('.form-group-offer-specifications')
                    .children('.offer-specifications-style')
                    .children('.offer-specifications-style-text')
                    .text( (value).replace(/C:\\fakepath\\/, '') )
                ;
                $(this)
                    .parent('.form-group-offer-specifications')
                    .children('.offer-specifications-style')
                    .addClass('offer-specifications-style-with-file')
                ;
            }
        });
    },

    Upload : () => {

        $d.off('click', '.offer-upload-add').on('click', '.offer-upload-add', function(){

            let $clone = $('.form-group-offer-upload[data-index="1"]').clone();
            let index = $('.form-group-offer-upload').length + 1;

            $clone
                .prepend('<div class="form-group-offer-upload-remove"><i class="fas fa-trash"></i></div>')
            ;
            $clone
                .find('.form-group-offer-upload-clear')
                .remove()
            ;
            $clone
                .find('a')
                .parent('div')
                .remove()
            ;
            $clone
                .attr('data-index', index)
            ;
            $clone
                .children('label')
                .children('.offer-upload-index-number')
                .text(index)
            ;
            $clone
                .children('input[name="offer_upload[1]"]')
                .val('')
                .attr('name', 'offer_upload['+index+']')
            ;
            if ( $clone.children('.offer-upload-digital-signature').length ) {

                $clone
                    .children('.offer-upload-digital-signature')
                    .children('input[name="offer_upload_digital_signature[1]"]')
                    .prop('checked', false)
                    .removeAttr('checked')
                    .attr('name', 'offer_upload_digital_signature['+index+']')
                ;
            }
            let baseText = $clone.children('.offer-upload-style').children('.offer-upload-style-text').attr('base-text');
            $clone
                .children('.offer-upload-style')
                .children('.offer-upload-style-text')
                .text(baseText)
            ;
            $clone
                .children('.offer-upload-style')
                .removeClass('offer-upload-style-with-file')
            ;
            $($clone)
                .insertAfter( $('.form-group-offer-upload[data-index="'+(index-1)+'"]') )
            ;
        });

        $d.off('click', '.offer-upload-style').on('click', '.offer-upload-style', function(){

            $(this)
                .parent('.form-group-offer-upload')
                .children('.form-control-offer-upload')
                .trigger('click')
            ;
        });

        $d.off('click', '.form-group-offer-upload-clear').on('click', '.form-group-offer-upload-clear', function(){

            let $parent = $(this).parent('.form-group-offer-upload');

            $parent
                .children('.form-control-offer-upload')
                .val('')
            ;

            $parent
                .find('a')
                .parent('div')
                .remove()
            ;

            $parent
                .children('.offer-upload-digital-signature')
                .children('input[type="checkbox"]')
                .prop('checked', false)
                .removeAttr('checked')
            ;

            $parent
                .children('.offer-upload-style')
                .removeClass('offer-upload-style-with-file')
            ;

            let baseText = $parent.children('.offer-upload-style').children('.offer-upload-style-text').attr('base-text')
            $parent
                .children('.offer-upload-style')
                .children('.offer-upload-style-text')
                .text(baseText)
            ;
        });

        $d.off('change', '.form-control-offer-upload').on('change', '.form-control-offer-upload', function(){

            let value = $(this).val();
            let index = $(this).parent().attr('data-index');
            if (index == '1' && !$(this).parent().children('.form-group-offer-upload-clear').length) {
                $(this).parent().prepend('<div class="form-group-offer-upload-clear"><i class="fas fa-eraser"></i></div>');
            }

            if (value.length) {

                $(this)
                    .parent('.form-group-offer-upload')
                    .children('.offer-upload-style')
                    .children('.offer-upload-style-text')
                    .text( (value).replace(/C:\\fakepath\\/, '') )
                ;
                $(this)
                    .parent('.form-group-offer-upload')
                    .children('.offer-upload-style')
                    .addClass('offer-upload-style-with-file')
                ;
            }
        });

        $d.off('click', '.form-group-offer-upload-remove').on('click', '.form-group-offer-upload-remove', function(){
            $(this).parent('.form-group-offer-upload').remove();

            $('.form-group-offer-upload').each(function(index, item) {

                $(item)
                    .attr('data-index', (index + 1))
                ;

                $(item)
                    .children('label')
                    .children('.offer-upload-index-number')
                    .text( (index + 1) )
                ;
            });
        });
    },

    SubmitFormCheck : () => {

        let draftText = $('button[name="offer_response"]').attr('draft-text');

        $('button[name="offer_response"]').text('');

        setTimeout(function() { 

            let offer_admin_edit = $('#offer_admin_edit').length || $('#admin_offer_show').length ? true : false;
            let countLinkSignature = $('#offer_form').find('.link-digital-signature').length;

            if (countLinkSignature && countLinkSignature > 0 && !offer_admin_edit) {
                
                let digitalSignatureText = $('button[name="offer_response"]').attr('digital-signature-text');

                $('button[name="offer_response"]').attr('disabled', 'disabled').prop('disabled', true).val(0);
                $('button[name="offer_response"]')
                    .css({
                        'color': 'white',
                        'background-color': 'red',
                        'border': '1px solid red'
                    })
                    .removeClass('offer-as-digital-signature')
                    .addClass('offer-as-digital-signature')
                ;
                $('button[name="offer_response"]').text( digitalSignatureText+' ('+countLinkSignature+')').show();

            } else {

                if ($('#offer_information_sheet_to_be_completed_by_the_subcontractor_completed_empty').length && !offer_admin_edit) {

                    let buttonTextWarning = $('#offer_information_sheet_to_be_completed_by_the_subcontractor_completed_empty').attr('button-text-warning');

                    $('button[name="offer_response"]').attr('disabled', 'disabled').prop('disabled', true).val(0);
                    $('button[name="offer_response"]')
                        .css({
                            'color': 'white',
                            'background-color': 'red',
                            'border': '1px solid red'
                        })
                        .removeClass('offer-as-digital-signature')
                        .addClass('offer-as-digital-signature')
                    ;
                    $('button[name="offer_response"]').text( buttonTextWarning ).show();
                } else {

                    $('button[name="offer_response"]').text( draftText ).show();
                }
            }
        }, 1000);
    },

    CheckSubcontractorSelection : () => {

        let subcontractors_selected = 0;

        if ($('#form_submit_before').length && $('#offer_state').length) {

            let state = $('#offer_state').val();

            if (state != 'published') {

                if (!$('#form_submit_before').hasClass('js-offer-subcontractor-not-selected')) {
                    $('#form_submit_before').addClass('js-offer-subcontractor-not-selected');
                }

                let default_text = $('#form_submit_before').attr('default-text');
                let subcontractor_is_empty_text = $('#form_submit_before').attr('subcontractor-is-empty-text');
                let subcontractor_selected_text = $('#form_submit_before').attr('subcontractor-selected-text');

                if ($('#subcontractor_list_table').length && $('#subcontractor_list_table').find('tbody').children('tr').length) {

                    let trLength = $('#subcontractor_list_table').find('tbody').children('tr').length;
                    let count = 0;

                    $('#subcontractor_list_table').find('tbody').children('tr').each(function(index, item) {

                        if ($(item).find('input[name="offer_response_select[]"]').length && $(item).find('input[name="offer_response_select[]"]').prop('checked')) {
                            subcontractors_selected++;
                        }
                        count++;

                        if (count === trLength) {
                            if (subcontractors_selected >= 2) {

                                $('#form_submit_before').removeClass('js-offer-subcontractor-not-selected');
                                $('#form_submit_before').removeClass('offer-subcontractor-not-selected');
                                $('#form_submit_before').text( default_text );

                            } else {

                                if (!$('#form_submit_before').hasClass('offer-subcontractor-not-selected')) {
                                    $('#form_submit_before').addClass('offer-subcontractor-not-selected');
                                }
                                $('#form_submit_before').text( subcontractor_selected_text );
                            }
                        }
                    });

                } else {

                    if (!$('#form_submit_before').hasClass('offer-subcontractor-not-selected')) {
                        $('#form_submit_before').addClass('offer-subcontractor-not-selected');
                    }
                    $('#form_submit_before').text( subcontractor_is_empty_text );
                }
            }
        }

    },

    DigitalSignatureBefore : () => {

        let digital_signature_before = $('#digital_signature_before_field').length ? true : false;
        let turn_number = $('#offer_turn_number').length ? parseInt($('#offer_turn_number').val()) : 0;
        let offer_admin_edit = $('#offer_admin_edit').length ? true : false;
        let digital_signature_before_value = $('#digital_signature_before').length ? $('#digital_signature_before').val() : '1';

        if (digital_signature_before && turn_number < 2 && !offer_admin_edit) {

            if (digital_signature_before_value != '1') {
                $('#offer_modal_digital_signature_before')
                    .addClass('offer-modal-digital-signature-before-visible')
                ;
            }
            if ($('#offer_form_left_ajax').length) {
                $('#offer_form_left_ajax').remove();
            }
            if ($('#offer_form_right_ajax').length) {
                $('#offer_form_right_ajax').remove();
            }
        }

        if (
            $('#offer_form').length && 
            $('#offer_modal_digital_signature_before').length && 
            !offer_admin_edit && 
            $('#offer_modal_digital_signature_before').hasClass('offer-modal-digital-signature-before-visible')
        ) {
            $('#offer_form').remove();

            //if ($('.link-digital-signature[data-type="offer_confidentiality_agreement"]').length) {
            if ($('.link-digital-signature-before[data-type="offer_confidentiality_agreement"]').length) {

                $('#_offer_modal_digital_signature_confirm').hide();

                let cancelText = $('#_offer_modal_digital_signature_cancel').attr('cancel-text');
                let loadText = $('#_offer_modal_digital_signature_cancel').attr('load-text');

                $('#_offer_modal_digital_signature_cancel').text(loadText);

                //let filepath = $('.link-digital-signature[data-type="offer_confidentiality_agreement"]').attr('data-href');
                //let type = $('.link-digital-signature[data-type="offer_confidentiality_agreement"]').attr('data-type');
                //    type = type.trim();

                let filepath = $('.link-digital-signature-before[data-type="offer_confidentiality_agreement"]').attr('data-href');
                let type = $('.link-digital-signature-before[data-type="offer_confidentiality_agreement"]').attr('data-type');
                    type = type.trim();

                if (filepath && type) {

                    let filename = filepath.split('/');
                        filename = filename[filename.length - 1];

                    let ext = filepath.split('.');
                        ext = ext[ext.length - 1];
                        ext = ext.toLowerCase();

                    if (ext === 'pdf') {

                        Offer.AjaxLoadFileView(filepath, function(data){

                            if ($('#file_view_ajax_error').length) {
                                $('#_offer_modal_digital_signature_confirm').hide();
                            } else {

                                //$('#_offer_modal_digital_signature_confirm').show();
                                //$('#_offer_modal_digital_signature_cancel').text(cancelText);

                                let fileurl = window.location.origin+filepath;

                                function toDataUrl(url, callback) {
                                    var xhr = new XMLHttpRequest();
                                    xhr.onload = function() {
                                        callback(xhr.response);
                                    };
                                    xhr.open('GET', url);
                                    xhr.responseType = 'blob';
                                    xhr.send();
                                }

                                if (filename) {

                                    let formAction = $('#_offer_modal_digital_signature_form').attr('action');
                                    let urlFail = $('#_offer_modal_digital_signature_form').attr('data-url-fail');
                                    let urlSuccess = $('#_offer_modal_digital_signature_form').attr('data-url-success');
                                    let fullUrl = formAction+'?url_retour_fail='+urlFail+'/'+type+'/'+filename+'&url_retour_success='+urlSuccess+'/'+type+'/'+filename;

                                    $('#_offer_modal_digital_signature_form').attr('action', fullUrl);

                                    if ($('#_offer_signature_type').length && type === 'offer_information_sheet_to_be_completed_by_the_subcontractor') {
                                        $('#_offer_signature_type').val('Questionnaire');
                                    } else {
                                        $('#_offer_signature_type').val('AccordConfidentialite');
                                    }
                                
                                    let file;

                                    toDataUrl(fileurl, function(x){

                                        file = x;
                                        
                                        const dataTransfer = new ClipboardEvent('').clipboardData || new DataTransfer();

                                        dataTransfer.items.add(new File([file], filename, {
                                            type: "application/pdf",
                                        }));

                                        document.querySelector('#_offer_pdf_to_sign').files = dataTransfer.files;

                                        $('#_offer_modal_digital_signature_confirm').show();
                                        $('#_offer_modal_digital_signature_cancel').text(cancelText);
                                    });
                                } else {
                                    $('#_offer_modal_digital_signature_cancel').text(cancelText);
                                }
                            }
                        });
                    } else {
                        $('#_offer_modal_digital_signature_cancel').text(cancelText);
                    }
                }
            }
        }
        Offer.SubmitFormCheck();
    },

    OnLoad : () => {

        let tableMod = require('./Table');

        if ($('#subcontractor_list_table').length) {

            let tableParams = {
                "language": {
                    "sUrl": '/publicmarket/datatable/lang/subcontractor',
                },
                "aLengthMenu": [ [10, 25, 50, 100, 1000, -1], [10, 25, 50, 100, 1000, 'Tous'] ],
                fnDrawCallback: function(e) {
                    console.log(e);
                }
            }

            tableMod.DataTable('subcontractor_list_table', tableParams);
        }

        $('button[name="offer_response"]').hide();

        let turn_number = $('#offer_turn_number').length ? parseInt($('#offer_turn_number').val()) : 0;
        let offer_admin_edit = $('#offer_admin_edit').length || $('#admin_offer_show').length ? true : false;

        // >> --- Check de la catégorie pour charger le bon formaulaire. ---
        if ($('select[name="offer_category"]').length) {

            let form = $('select[name="offer_category"] :selected').attr('data-type');
            let uid =  $('select[name="offer_category"]').val();

            let offer_information_sheet_to_be_completed_by_the_subcontractor = false;
            if ($('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').length) {
                offer_information_sheet_to_be_completed_by_the_subcontractor = true;
            }

            if (form && uid) {

                let $formLeft = $('#offer_form_left_ajax');

                if ($formLeft.length) {

                    $formLeft.html('');
                    $formLeft.removeAttr('class')
                    $formLeft.addClass('offer-form-right-ajax offer-form-ajax-'+form);

                    Offer.AjaxFormLoad({
                        form : form,
                        col : 'left',
                        uid: uid,
                        readonly : $('input[name="form_readonly"]').length ? $('input[name="form_readonly"]').val() : 0,
                        disabled : $('input[name="form_disabled"]').length ? $('input[name="form_disabled"]').val() : 0,
                        offer_id: $('#offer_id').length ? $('#offer_id').val() : '0',
                        offer_admin_edit : offer_admin_edit,
                        offer_response_view_society_id : $('#offer_response_view_society_id').length ? $('#offer_response_view_society_id').val() : null
                    }, function(result){
                        if (result) {

                            $formLeft.html(result);

                            if (
                                offer_information_sheet_to_be_completed_by_the_subcontractor && 
                                $formLeft.find('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').length
                            ) {
                                $formLeft.find('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').remove();
                            }

                            Offer.LoadLots();
                            Offer.DigitalSignatureBefore();
                        }
                    });
                }

                let $formRight = $('#offer_form_right_ajax');

                if ($formRight.length) {

                    $formRight.html('');
                    $formRight.removeAttr('class')
                    $formRight.addClass('offer-form-right-ajax offer-form-ajax-'+form);

                    Offer.AjaxFormLoad({
                        form : form,
                        col : 'right',
                        uid: uid,
                        readonly : $('input[name="form_readonly"]').length ? $('input[name="form_readonly"]').val() : 0,
                        disabled : $('input[name="form_disabled"]').length ? $('input[name="form_disabled"]').val() : 0,
                        offer_id: $('#offer_id').length ? $('#offer_id').val() : 0,
                        offer_admin_edit : offer_admin_edit,
                        offer_response_view_society_id : $('#offer_response_view_society_id').length ? $('#offer_response_view_society_id').val() : null
                    }, function(result){
                        if (result) {

                            $formRight.html(result);

                            if (
                                offer_information_sheet_to_be_completed_by_the_subcontractor && 
                                $formRight.find('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').length
                            ) {
                                $formRight.find('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').remove();
                            }

                            Offer.LoadLots();
                            Offer.DigitalSignatureBefore();
                        }
                    });
                }
            }
        }
        // <<

        if (turn_number > 1 && !offer_admin_edit) {
            Offer.CheckSubcontractorSelection();
        }
        //}

        if ($('input[name="offer_is_last_turn"]').length) {

            let value = parseInt($('input[name="offer_is_last_turn"]:checked').val());

            if (value === 1) {
                $('#closing_message_container').show();
            } else {

                $('#closing_message_container').hide();
            }
        }

        /*if ($('#offer_presentation_text').length) {
            $('#offer_presentation_text').css('height', 'auto');
            $('#offer_presentation_text').height($('#offer_presentation_text')[0].scrollHeight+'px');
        }*/

        if ($('textarea.form-control-auto-height').length) {

            $('textarea.form-control-auto-height').each(function(index, item){
                $(item).css('height', 'auto');
                $(item).height($(item)[0].scrollHeight+'px');
            });
        }
    },

    Lots : () => {

        $d.off('change', '.form-file-benefit').on('change', '.form-file-benefit', function(e){

            let id = $(this).attr('id')
            if (e.target.files.length) {
                $('label[for="'+id+'"]').text( e.target.files[0].name );
            }
        });

        $d.off('change', 'input[name="offer_lots"]').on('change', 'input[name="offer_lots"]', function(){

            let value = parseInt($(this).val());

            if (value <= 0) {
                $(this).val(1);
                value = 1;
            }

            if ($('.offer-lot-item').length && value < $('.offer-lot-item').length) {

                $('.offer-lot-item').each(function(index, item){
                    if ( (index + 1) > value ) {
                        $(item).remove();
                    }
                });
            }
            Offer.LoadLots(value);
        });

        $d.off('click', '.prestation-delete').on('click', '.prestation-delete', function(){

            let $container = $(this).parent('.lot-item-prestation').parent('.offer-lot-item');

            let index = $container.attr('data-index');
            $(this).parent('.lot-item-prestation').remove();

            $container
                .children('.lot-item-prestation[data-prestation="1"]')
                .find('.prestation-add')
                .removeClass('prestation-add-hidden')
            ;
        });

        $d.off('click', '.prestation-add').on('click', '.prestation-add', function(){

            let $container = $(this).parent('.lot-item-prestation').parent('.offer-lot-item');
            let index = $container.attr('data-index');
            let $prestationClone = $('.offer-lot-item-clone').children('.lot-item-prestation[data-prestation="2"]').clone();

            // >> --- PRESTATION 2 ---
            // >> Prestation 2 description
            $prestationClone
                .find('[name="offer_benefit_2_description"]')
                .attr('name', 'offer_lots_item['+index+'][offer_benefit_2_description]')
            ;
            // >> Prestation 2 method
            $prestationClone
                .find('[name="offer_benefit_2_billing_method"]')
                .attr('name', 'offer_lots_item['+index+'][offer_benefit_2_billing_method]')
            ;
            // >> Prestation 2 mode de facturation
            $prestationClone
                .find('[name="offer_benefit_2_billing_variable"]')
                .attr('name', 'offer_lots_item['+index+'][offer_benefit_2_billing_variable]')
            ;
            // <<
            // >> Prestation 2 mode de facturation + variable
            $prestationClone
                .find('[name="offer_benefit_2_billing_forfait_variable"]')
                .attr('name', 'offer_lots_item['+index+'][offer_benefit_2_billing_forfait_variable]')
            ;
            // <<

            // >> Prestation 2 mode de facturation + variable
            $prestationClone
                .find('[name="offer_benefit_2_amount"]')
                .attr('name', 'offer_lots_item['+index+'][offer_benefit_2_amount]')
            ;
            // <<

            // >> Prestation 2 mode de facturation + variable
            $prestationClone
                .find('[for="offer_benefit_2_file"]')
                .attr('for', 'offer_lots_item_'+index+'_offer_benefit_2_amount')
            ;
            // <<

            // >> Prestation 2 mode de facturation + variable
            $prestationClone
                .find('[name="offer_benefit_2_file"]')
                .attr({
                    'name' : 'offer_lots_item['+index+'][offer_benefit_2_file]',
                    'id' : 'offer_lots_item_'+index+'_offer_benefit_2_file'
                })
            ;
            // <<

            $prestationClone
                .find('.prestation-delete')
                .children('.prestation-lot-number')
                .text(index)
            ;
            $prestationClone
                .removeClass('lot-item-prestation-hidden')
            ;

            $container.append($prestationClone);

            $(this).addClass('prestation-add-hidden');
        });

        $d.off('click', '.lot-item-prestation-up').on('click', '.lot-item-prestation-up', function(){

            let $that = $(this);

            $that
                .parent('.lot-item-show-btn')
                .parent('.offer-lot-item')
                .find('.lot-item-prestation')
                .removeClass('lot-item-prestation-hidden')
            ;

            $that
                .parent('.lot-item-show-btn')
                .html('<i class="fas fa-chevron-down lot-item-prestation-down"></i>')
            ;
        });

        $d.off('click', '.lot-item-prestation-down').on('click', '.lot-item-prestation-down', function(){

            let $that = $(this);

            $that
                .parent('.lot-item-show-btn')
                .parent('.offer-lot-item')
                .find('.lot-item-prestation')
                .addClass('lot-item-prestation-hidden')
            ;

            $that
                .parent('.lot-item-show-btn')
                .html('<i class="fas fa-chevron-up lot-item-prestation-up"></i>')
            ;
        });

        $d.off('change', '.benefit-selector-master').on('change', '.benefit-selector-master', function(){

            let value = $(this).val();
            let $parent = $(this).parent('.field-flex').parent('.form-group-field-flex');
            Offer.LotForfaitAction(value, $parent);
        });
    },

    LotForfaitAction : (value, $parent) => {

        if (value === 'forfait') {

            $parent
                .find('.field-flex > .benefit-selector-label-forfait-variable')
                .hide()
            ;
            $parent
                .find('.field-flex > .benefit-selector-option-forfait-variable')
                .hide()
                .removeAttr('required')
                .prop('required', false)
            ;
            $parent
                .find('.field-flex > .benefit-selector-label-variable')
                .hide()
            ;
            $parent
                .find('.field-flex > .benefit-selector-option-variable')
                .hide()
                .removeAttr('required')
                .prop('required', false)
            ;

            // >> SST FIELDS DISPLAY ACTION
            $parent
                .parent('.form-group')
                .parent('.lot-item-prestation')
                .children('.offer-benefit-stt-container')
                .children('.offer-benefit-stt-fields-container-forfait')
                .show()
            ;

            $parent
                .parent('.form-group')
                .parent('.lot-item-prestation')
                .children('.offer-benefit-stt-container')
                .children('.offer-benefit-stt-fields-container-variable')
                .hide()
            ;

        } else if (value === 'forfait_variable') {

            $parent
                .find('.field-flex > .benefit-selector-label-forfait-variable')
                .show()
            ;
            $parent
                .find('.field-flex')
                .children('.benefit-selector-option-forfait-variable')
                .show()
                .attr('required', 'required')
            ;
            $parent
                .find('.field-flex > .benefit-selector-label-variable')
                .hide()
            ;
            $parent
                .find('.field-flex > .benefit-selector-option-variable')
                .hide()
                .removeAttr('required')
                .prop('required', false)
            ;

            // >> SST FIELDS DISPLAY ACTION
            $parent
                .parent('.form-group')
                .parent('.lot-item-prestation')
                .children('.offer-benefit-stt-container')
                .children('.offer-benefit-stt-fields-container-forfait')
                .show()
            ;

            $parent
                .parent('.form-group')
                .parent('.lot-item-prestation')
                .children('.offer-benefit-stt-container')
                .children('.offer-benefit-stt-fields-container-variable')
                .show()
            ;
            
        } else if (value === 'variable') {

            $parent
                .find('.field-flex > .benefit-selector-label-forfait-variable')
                .hide()
            ;
            $parent
                .find('.field-flex > .benefit-selector-option-forfait-variable')
                .hide()
                .removeAttr('required')
                .prop('required', false)
            ;
            $parent
                .find('.field-flex > .benefit-selector-label-variable')
                .show()
            ;
            $parent
                .find('.field-flex')
                .children('.benefit-selector-option-variable')
                .show()
                .attr('required', 'required')
            ;

            // >> SST FIELDS DISPLAY ACTION
            $parent
                .parent('.form-group')
                .parent('.lot-item-prestation')
                .children('.offer-benefit-stt-container')
                .children('.offer-benefit-stt-fields-container-forfait')
                .hide()
            ;

            $parent
                .parent('.form-group')
                .parent('.lot-item-prestation')
                .children('.offer-benefit-stt-container')
                .children('.offer-benefit-stt-fields-container-variable')
                .show()
            ;
        }

        $('.offer-lot-item-clone')
            .find('select, textarea, input')
            .removeAttr('required')
            .prop('required', false)
        ; 
    },

    LoadLots : (value=false) => {

        let originalValue = !value ? false : true;

        if (!value && $('input[name="offer_lots"]').length) {
            value = parseInt($('input[name="offer_lots"]').val())
        } 

        for (var i=1; i <= value; i++) {

            if (!$('.offer-lot-item[data-index="'+i+'"]').length) {

                let index = i;
                let $clone = $('.offer-lot-item-clone').clone();
    
                $clone
                    .addClass('offer-lot-item')
                    .removeClass('offer-lot-item-clone')
                    .attr('data-index', index)
                ;
                $clone
                    .find('.lot-item-title')
                    .children('span')
                    .text(index)
                ;
                $clone
                    .find('.lot-item-prestation[data-prestation="1"]')
                    .find('.prestation-add')
                    .children('.prestation-lot-number')
                    .text(index)
                ;
                $clone
                    .find('.lot-item-prestation[data-prestation="2"]')
                    .find('.prestation-delete')
                    .children('.prestation-lot-number')
                    .text(index)
                ;

                let offer_benefit_1_description = '';
                let offer_benefit_1_billing_method = '';
                let offer_benefit_1_billing_variable = '';
                let offer_benefit_1_billing_forfait_variable = '';

                let offer_benefit_2_description = '';
                let offer_benefit_2_billing_method = '';
                let offer_benefit_2_billing_variable = '';
                let offer_benefit_2_billing_forfait_variable = '';

                let offer_benefit_1_amount = $('input[name="'+index+'_response_offer_benefit_amount_1"]').length ? $('input[name="'+index+'_response_offer_benefit_amount_1"]').val() : '';
                let offer_benefit_1_file = $('input[name="'+index+'_response_offer_benefit_file_1"]').length ? $('input[name="'+index+'_response_offer_benefit_file_1"]').val() : '';

                let offer_benefit_1_amount_variable = $('input[name="'+index+'_response_offer_benefit_amount_variable_1"]').length ? $('input[name="'+index+'_response_offer_benefit_amount_variable_1"]').val() : '';
                let offer_benefit_1_file_variable = $('input[name="'+index+'_response_offer_benefit_file_variable_1"]').length ? $('input[name="'+index+'_response_offer_benefit_file_variable_1"]').val() : '';

                let offer_benefit_2_amount = $('input[name="'+index+'_response_offer_benefit_amount_2"]').length ? $('input[name="'+index+'_response_offer_benefit_amount_2"]').val() : '';
                let offer_benefit_2_file = $('input[name="'+index+'_response_offer_benefit_file_2"]').length ? $('input[name="'+index+'_response_offer_benefit_file_2"]').val() : '';

                let offer_benefit_2_amount_variable = $('input[name="'+index+'_response_offer_benefit_amount_variable_2"]').length ? $('input[name="'+index+'_response_offer_benefit_amount_variable_2"]').val() : '';
                let offer_benefit_2_file_variable = $('input[name="'+index+'_response_offer_benefit_file_variable_2"]').length ? $('input[name="'+index+'_response_offer_benefit_file_variable_2"]').val() : '';

                let prestation2Add = false;// >> Seulement un lot, passer a true si 2 lot à l'ajout d'un nouveau lot...

                if ($('input[name="'+index+'_offer_lot_data"]').length) {

                    let jsonData = ($('input[name="'+index+'_offer_lot_data"]').val()).trim();
                    if (jsonData.length) {

                        jsonData = JSON.parse(jsonData);

                        // >> Prestation 1
                        offer_benefit_1_description = jsonData.offer_benefit_1_description != undefined ? jsonData.offer_benefit_1_description : '';
                        offer_benefit_1_billing_method = jsonData.offer_benefit_1_billing_method != undefined ? jsonData.offer_benefit_1_billing_method : '';
                        offer_benefit_1_billing_variable = jsonData.offer_benefit_1_billing_variable != undefined ? jsonData.offer_benefit_1_billing_variable : '';
                        offer_benefit_1_billing_forfait_variable = jsonData.offer_benefit_1_billing_forfait_variable != undefined ? jsonData.offer_benefit_1_billing_forfait_variable : '';

                        // >> Prestation 2
                        offer_benefit_2_description = jsonData.offer_benefit_2_description != undefined ? jsonData.offer_benefit_2_description : '';
                        offer_benefit_2_billing_method = jsonData.offer_benefit_2_billing_method != undefined ? jsonData.offer_benefit_2_billing_method : '';
                        offer_benefit_2_billing_variable = jsonData.offer_benefit_2_billing_variable != undefined ? jsonData.offer_benefit_2_billing_variable : '';
                        offer_benefit_2_billing_forfait_variable = jsonData.offer_benefit_2_billing_forfait_variable != undefined ? jsonData.offer_benefit_2_billing_forfait_variable : '';
                    }
                }
                if (!originalValue && offer_benefit_2_description.length < 1 && offer_benefit_2_billing_method.length < 1 && offer_benefit_2_billing_variable.length < 1) {
                    prestation2Add = false;
                }
                // >> --- PRESTATION 1 ---
                // >> Prestation 1 description
                /*$clone
                    .find('[name="offer_benefit_1_description"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_description]',
                        'required' : 'required'
                    })
                    .val(offer_benefit_1_description)
                ;*/
                $clone
                    .find('[name="offer_benefit_1_description"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_description]'
                    })
                    .val(offer_benefit_1_description)
                ;
                // >> Prestation 1 mode de facturation
                /*$clone
                    .find('[name="offer_benefit_1_billing_method"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_billing_method]',
                        'required' : 'required'
                    })
                    .children('option[value="'+offer_benefit_1_billing_method+'"]')
                    .prop('selected', true)
                    .attr('selected','selected')
                ;*/
                $clone
                    .find('[name="offer_benefit_1_billing_method"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_billing_method]'
                    })
                    .children('option[value="'+offer_benefit_1_billing_method+'"]')
                    .prop('selected', true)
                    .attr('selected','selected')
                ;

                // >> Prestation 1 variable
                /*$clone
                    .find('[name="offer_benefit_1_billing_variable"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_billing_variable]',
                        'required' : 'required'
                    })
                    .children('option[value="'+offer_benefit_1_billing_variable+'"]')
                    .prop('selected', true)
                    .attr('selected','selected')
                ;*/
                $clone
                    .find('[name="offer_benefit_1_billing_variable"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_billing_variable]'
                    })
                    .children('option[value="'+offer_benefit_1_billing_variable+'"]')
                    .prop('selected', true)
                    .attr('selected','selected')
                ;
                // <<

                // >> Prestation 1 forfait + variable
                /*$clone
                    .find('[name="offer_benefit_1_billing_forfait_variable"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_billing_forfait_variable]',
                        'required' : 'required'
                    })
                    .children('option[value="'+offer_benefit_1_billing_forfait_variable+'"]')
                    .prop('selected', true)
                    .attr('selected','selected')
                ;*/
                $clone
                    .find('[name="offer_benefit_1_billing_forfait_variable"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_billing_forfait_variable]'
                    })
                    .children('option[value="'+offer_benefit_1_billing_forfait_variable+'"]')
                    .prop('selected', true)
                    .attr('selected','selected')
                ;
                // <<

                // >> Prestation 1 Label
                $clone
                    .find('[name="offer_benefit_1_amount"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_amount]'
                    })
                    .val(offer_benefit_1_amount)
                ;
                // <<

                 // >> Prestation 1 File Label
                 $clone
                    .find('[for="offer_benefit_1_file"]')
                    .attr({
                        'for' : 'offer_lots_item_'+index+'_offer_benefit_1_file'
                    });
                ;
                // <<

                 // >> Prestation 1 File
                 $clone
                    .find('[name="offer_benefit_1_file"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_file]',
                        'id' : 'offer_lots_item_'+index+'_offer_benefit_1_file'
                    });
                ;
                // <<


                // >> Prestation 1 Label variable
                $clone
                    .find('[name="offer_benefit_1_amount_variable"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_amount_variable]'
                    })
                    .val(offer_benefit_1_amount_variable)
                ;
                // <<

                 // >> Prestation 1 File Label variable
                 $clone
                    .find('[for="offer_benefit_1_file_variable"]')
                    .attr({
                        'for' : 'offer_lots_item_'+index+'_offer_benefit_1_file_variable'
                    });
                ;
                // <<

                 // >> Prestation 1 File variable
                 $clone
                    .find('[name="offer_benefit_1_file_variable"]')
                    .attr({
                        'name' : 'offer_lots_item['+index+'][offer_benefit_1_file_variable]',
                        'id' : 'offer_lots_item_'+index+'_offer_benefit_1_file_variable'
                    });
                ;
                // <<
                

                if (prestation2Add) {

                    // >> --- PRESTATION 2 ---
                    // >> Prestation 2 description
                    /*$clone
                        .find('[name="offer_benefit_2_description"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_description]',
                            'required' : 'required'
                        })
                        .val(offer_benefit_2_description)
                    ;*/
                    $clone
                        .find('[name="offer_benefit_2_description"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_description]'
                        })
                        .val(offer_benefit_2_description)
                    ;
                    // >> Prestation 2 mode de facturation
                    /*$clone
                        .find('[name="offer_benefit_2_billing_method"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_billing_method]',
                            'required' : 'required'
                        })
                        .children('option[value="'+offer_benefit_2_billing_method+'"]')
                        .prop('selected', true)
                        .attr('selected','selected')
                    ;*/
                    $clone
                        .find('[name="offer_benefit_2_billing_method"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_billing_method]'
                        })
                        .children('option[value="'+offer_benefit_2_billing_method+'"]')
                        .prop('selected', true)
                        .attr('selected','selected')
                    ;
                    // >> Prestation 2 mode de facturation
                    /*$clone
                        .find('[name="offer_benefit_2_billing_variable"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_billing_variable]',
                            'required' : 'required'
                        })
                        .children('option[value="'+offer_benefit_2_billing_variable+'"]')
                        .prop('selected', true)
                        .attr('selected','selected')
                    ;*/
                    $clone
                        .find('[name="offer_benefit_2_billing_variable"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_billing_variable]'
                        })
                        .children('option[value="'+offer_benefit_2_billing_variable+'"]')
                        .prop('selected', true)
                        .attr('selected','selected')
                    ;
                    // <<
                    // >> Prestation 2 mode de facturation
                    /*$clone
                        .find('[name="offer_benefit_2_billing_forfait_variable"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_billing_forfait_variable]',
                            'required' : 'required'
                        })
                        .children('option[value="'+offer_benefit_2_billing_forfait_variable+'"]')
                        .prop('selected', true)
                        .attr('selected','selected')
                    ;*/
                    $clone
                        .find('[name="offer_benefit_2_billing_forfait_variable"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_billing_forfait_variable]'
                        })
                        .children('option[value="'+offer_benefit_2_billing_forfait_variable+'"]')
                        .prop('selected', true)
                        .attr('selected','selected')
                    ;
                    // <<

                    // >> Prestation 2 Amount
                    $clone
                        .find('[name="offer_benefit_2_amount"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_amount]'
                        })
                        .val(offer_benefit_2_amount)
                    ;
                    // <<

                    // >> Prestation 2 File label
                    $clone
                        .find('[for="offer_benefit_2_file"]')
                        .attr({
                            'for' : 'offer_lots_item_'+index+'_offer_benefit_2_file'
                        })
                    ;
                    // <<

                    // >> Prestation 2 File
                    $clone
                        .find('[name="offer_benefit_2_file"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_file]',
                            'id' : 'offer_lots_item_'+index+'_offer_benefit_2_file'
                        })
                    ;
                    // <<

                    // >> Prestation 2 Amount variable
                    $clone
                        .find('[name="offer_benefit_2_amount_variable"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_amount_variable]'
                        })
                        .val(offer_benefit_2_amount_variable)
                    ;
                    // <<

                    // >> Prestation 2 File label variable
                    $clone
                        .find('[for="offer_benefit_2_file_variable"]')
                        .attr({
                            'for' : 'offer_lots_item_'+index+'_offer_benefit_2_file_variable'
                        })
                    ;
                    // <<

                    // >> Prestation 2 File variable
                    $clone
                        .find('[name="offer_benefit_2_file_variable"]')
                        .attr({
                            'name' : 'offer_lots_item['+index+'][offer_benefit_2_file_variable]',
                            'id' : 'offer_lots_item_'+index+'_offer_benefit_2_file_variable'
                        })
                    ;
                    // <<

                } else {

                    $clone
                        .find('.lot-item-prestation[data-prestation="2"]')
                        .remove()
                    ;
                    $clone
                        .find('.lot-item-prestation[data-prestation="1"]')
                        .find('.prestation-add')
                        .removeClass('prestation-add-hidden')
                    ;
                }

                if (i === 1) {

                    $clone
                        .find('.lot-item-show-btn')
                        .html('<i class="fas fa-chevron-down lot-item-prestation-down"></i>')
                    ;

                    $clone
                        .find('.lot-item-prestation')
                        .removeClass('lot-item-prestation-hidden')
                    ;
                }
                $('.offer-lot-items').append( $clone );

                if (offer_benefit_1_file != '') {

                    $('<input type="hidden" name="offer_lots_item['+index+'][offer_benefit_1_file_current]" value="'+offer_benefit_1_file+'">')
                        .insertAfter( $clone )
                    ;

                    if ($('[for="offer_lots_item_'+index+'_offer_benefit_1_file"]').length) {
                        $('[for="offer_lots_item_'+index+'_offer_benefit_1_file"]').text( offer_benefit_1_file );
                    }
                }

                if (offer_benefit_1_file_variable != '') {

                    $('<input type="hidden" name="offer_lots_item['+index+'][offer_benefit_1_file_variable_current]" value="'+offer_benefit_1_file_variable+'">')
                        .insertAfter( $clone )
                    ;

                    if ($('[for="offer_lots_item_'+index+'_offer_benefit_1_file_variable"]').length) {
                        $('[for="offer_lots_item_'+index+'_offer_benefit_1_file_variable"]').text( offer_benefit_1_file_variable );
                    }
                }

                if (offer_benefit_2_file != '') {

                    $('<input type="hidden" name="offer_lots_item['+index+'][offer_benefit_2_file_current]" value="'+offer_benefit_2_file+'">')
                        .insertAfter( $clone )
                    ;

                    if ($('[for="offer_lots_item_'+index+'_offer_benefit_2_file"]').length) {
                        $('[for="offer_lots_item_'+index+'_offer_benefit_2_file"]').text( offer_benefit_2_file );
                    }
                }

                if (offer_benefit_2_file_variable != '') {

                    $('<input type="hidden" name="offer_lots_item['+index+'][offer_benefit_2_file_variable_current]" value="'+offer_benefit_2_file_variable+'">')
                        .insertAfter( $clone )
                    ;

                    if ($('[for="offer_lots_item_'+index+'_offer_benefit_2_file_variable"]').length) {
                        $('[for="offer_lots_item_'+index+'_offer_benefit_2_file_variable"]').text( offer_benefit_2_file_variable );
                    }
                }
            }

            if (i === value) {

                $('.benefit-selector-master').each(function(index, item){

                    let value = $(item).val();
                    let $parent = $(item).parent('.field-flex').parent('.form-group-field-flex');
                    Offer.LotForfaitAction(value, $parent);
                })
            }
        }
    },

    Actions : () => {

        let offer_admin_edit = $('#offer_admin_edit').length ? true : false;

        $d.on("keydown", "form#offer_form", function(e) { 
            return e.key != "Enter";
        });

        $d.off('change', 'input[name="offer_response_select[]"]').on('change', 'input[name="offer_response_select[]"]', function(){
            Offer.CheckSubcontractorSelection();
        });

        $d.off('change', 'input[name="offer_is_last_turn"]').on('change', 'input[name="offer_is_last_turn"]', function(){

            let value = parseInt($(this).val());

            if (value === 1) {
                $('#closing_message_container').show();
            } else {
                $('#closing_message_container').hide();
            }
        });

        $d.off('click', '.link-digital-signature').on('click', '.link-digital-signature', function(){

            let link = $(this).attr('data-href');

            if ($(this).hasClass('link-digital-signature-not-stt')) {

                window.location.href = window.location.origin+link;
                return;

            } else {

                $('#offer_modal_digital_signature').show();
                $('#digital_signature_file_view').html('');
                $('#offer_modal_digital_signature_confirm').hide();
                $('#digital_signature_file_view_error_extension').hide();

                let cancelText = $('#offer_modal_digital_signature_cancel').attr('cancel-text');
                let loadText = $('#offer_modal_digital_signature_cancel').attr('load-text');

                $('#offer_modal_digital_signature_cancel').text(loadText);

                let filepath = $(this).attr('data-href');
                let type = $(this).attr('data-type');
                    type = type.trim();

                if (filepath && type) {

                    let filename = filepath.split('/');
                        filename = filename[filename.length - 1];

                    let ext = filepath.split('.');
                        ext = ext[ext.length - 1];
                        ext = ext.toLowerCase();

                    if (ext === 'pdf') {

                        Offer.AjaxLoadFileView(filepath, function(data){

                            $('#digital_signature_file_view').html(data);

                            if ($('#file_view_ajax_error').length) {
                                $('#offer_modal_digital_signature_confirm').hide();
                            } else {

                                //$('#offer_modal_digital_signature_confirm').show();
                                //$('#offer_modal_digital_signature_cancel').text(cancelText);

                                let fileurl = window.location.origin+filepath;

                                function toDataUrl(url, callback) {
                                    var xhr = new XMLHttpRequest();
                                    xhr.onload = function() {
                                        callback(xhr.response);
                                    };
                                    xhr.open('GET', url);
                                    xhr.responseType = 'blob';
                                    xhr.send();
                                }

                                if (filename) {

                                    let formAction = $('#offer_modal_digital_signature_form').attr('action');
                                    let urlFail = $('#offer_modal_digital_signature_form').attr('data-url-fail');
                                    let urlSuccess = $('#offer_modal_digital_signature_form').attr('data-url-success');
                                    let fullUrl = formAction+'?url_retour_fail='+urlFail+'/'+type+'/'+filename+'&url_retour_success='+urlSuccess+'/'+type+'/'+filename;

                                    $('#offer_modal_digital_signature_form').attr('action', fullUrl);

                                    if ($('#offer_signature_type').length && type === 'offer_information_sheet_to_be_completed_by_the_subcontractor') {
                                        $('#offer_signature_type').val('Questionnaire');
                                    } else {
                                        $('#offer_signature_type').val('AccordConfidentialite');
                                    }
                                
                                    let file;

                                    toDataUrl(fileurl, function(x){

                                        file = x;
                                        
                                        const dataTransfer = new ClipboardEvent('').clipboardData || new DataTransfer();

                                        dataTransfer.items.add(new File([file], filename, {
                                            type: "application/pdf",
                                        }));

                                        document.querySelector('#offer_pdf_to_sign').files = dataTransfer.files;

                                        $('#offer_modal_digital_signature_confirm').show();
                                        $('#offer_modal_digital_signature_cancel').text(cancelText);
                                    });
                                } else {
                                    $('#offer_modal_digital_signature_cancel').text(cancelText);
                                }
                            }
                        });
                    } else {
                        $('#digital_signature_file_view_error_extension').show();
                        $('#offer_modal_digital_signature_cancel').text(cancelText);
                    }
                }
            }
        });

        $d.off('click', '#offer_modal_digital_signature_cancel').on('click', '#offer_modal_digital_signature_cancel', function(){
            $('#offer_modal_digital_signature').hide();
        });
        $d.off('click', '#_offer_modal_digital_signature_cancel').on('click', '#_offer_modal_digital_signature_cancel', function(){
            $('#offer_modal_digital_signature_before').hide();
        });

        $d.off('click', '#offer_modal_digital_signature_confirm').on('click', '#offer_modal_digital_signature_confirm', function(){
            $('#offer_modal_digital_signature_form').trigger('submit');
            return;
        });

        $d.off('click', '#_offer_modal_digital_signature_confirm').on('click', '#_offer_modal_digital_signature_confirm', function(){
            $('#_offer_modal_digital_signature_form').trigger('submit');
            return;
        });

        // >> --- Offer state color ---
        if ( $('select[name="offer_state"]').length) {
            let stateValue = $('select[name="offer_state"]').val();
            $('select[name="offer_state"]')
                .removeAttr('class')
                .addClass('form-control offer-status-'+stateValue)
            ;
        }

        $d.off('change', 'select[name="offer_state"]').on('change', 'select[name="offer_state"]', function(){

            if ($('#form_submit_before').length && !$('#form_submit_before').hasClass('js-offer-subcontractor-not-selected')) {
                let value = $(this).val();
                $(this)
                    .removeAttr('class')
                    .addClass('form-control offer-status-'+value)
                ;

                if (value == 'published') {
                    $('#form_state_change_modal').show();
                }
            }
        });
        // <<

        $d.off('click', '#form_submit_before').on('click', '#form_submit_before', function(){

            if (!$(this).hasClass('js-offer-subcontractor-not-selected')) {

                let stateValue = $('select[name="offer_state"]').val();

                if (stateValue == 'published') {
                    $(this).hide();
                    $('#form_state_change_modal').hide();
                    $('#form_submit_modal').show();
                } else {
                    $('#form_submit').trigger('click');
                }
            }
        });

        $d.off('click', '#form_submit_cancel').on('click', '#form_submit_cancel', function(){
            $('#form_submit_before').show();
            $('#form_submit_modal').hide();
            $('#form_state_change_modal').hide();
            $('#offer_state').val('draft').trigger('change');
        });

        $d.off('click', '#form_state_change_cancel').on('click', '#form_state_change_cancel', function(){
            $('#form_state_change_modal').hide();
            $('#offer_state').val('draft').trigger('change');
        });

        // >> *** CHARGEMENT DU FORMULAIRE PERSONNALISE ***
        $d.off('change', 'select[name="offer_category"]').on('change', 'select[name="offer_category"]', function(){

            let digital_signature_before = $('#digital_signature_before_field').length ? true : false;
            let turn_number = $('#offer_turn_number').length ? parseInt($('#offer_turn_number').val()) : 0;
            let offer_admin_edit = $('#offer_admin_edit').length ? true : false;
            let digital_signature_before_value = $('#digital_signature_before').length ? $('#digital_signature_before').val() : '1'; 

            

            //if (digital_signature_before && turn_number < 2 && !offer_admin_edit) {
            if (digital_signature_before && turn_number < 2) {

                if (digital_signature_before_value != '1') {
                    $('#offer_modal_digital_signature_before')
                        .addClass('offer-modal-digital-signature-before-visible')
                    ;

                    /*if ($('#offer_form_left_ajax').length) {
                        $('#offer_form_left_ajax').remove();
                    }
                    if ($('#offer_form_right_ajax').length) {
                        $('#offer_form_right_ajax').remove();
                    }*/
                }
                /*if ($('#offer_form_left_ajax').length) {
                    $('#offer_form_left_ajax').remove();
                }
                if ($('#offer_form_right_ajax').length) {
                    $('#offer_form_right_ajax').remove();
                }*/
    
            } else {

                let form = $('select[name="offer_category"] :selected').attr('data-type');
                let uid =  $('select[name="offer_category"]').val();
                let $formLeft = $('#offer_form_left_ajax');

                let offer_information_sheet_to_be_completed_by_the_subcontractor = false;
                if ($('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').length) {
                    offer_information_sheet_to_be_completed_by_the_subcontractor = true;
                }

                if ($formLeft.length) {

                    // >> --- FORM LEFT ---
                    $formLeft.html('');
                    $formLeft.removeAttr('class')
                    $formLeft.addClass('offer-form-right-ajax offer-form-ajax-'+form);

                    Offer.AjaxFormLoad({
                        form : form,
                        uid: uid,
                        col : 'left',
                        readonly : $('input[name="form_readonly"]').length ? $('input[name="form_readonly"]').val() : 0,
                        disabled : $('input[name="form_disabled"]').length ? $('input[name="form_disabled"]').val() : 0,
                        offer_id: $('#offer_id').length ? $('#offer_id').val() : 0,
                        offer_admin_edit : offer_admin_edit,
                        offer_response_view_society_id : $('#offer_response_view_society_id').length ? $('#offer_response_view_society_id').val() : null
                    }, function(result){
                        if (result) {

                            $formLeft.html(result);

                            if (
                                offer_information_sheet_to_be_completed_by_the_subcontractor && 
                                $formLeft.find('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').length
                            ) {
                                $formLeft.find('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').remove();
                            }
                            Offer.LoadLots();
                            Offer.SubmitFormCheck();
                        }
                    });
                    // <<
                }
                // >> --- FORM RIGHT ---
                let $formRight = $('#offer_form_right_ajax');

                if ($formRight.length) {

                    $formRight.html('');
                    $formRight.removeAttr('class')
                    $formRight.addClass('offer-form-right-ajax offer-form-ajax-'+form);

                    Offer.AjaxFormLoad({
                        form : form,
                        uid: uid,
                        col : 'right',
                        readonly : $('input[name="form_readonly"]').length ? $('input[name="form_readonly"]').val() : 0,
                        disabled : $('input[name="form_disabled"]').length ? $('input[name="form_disabled"]').val() : 0,
                        offer_id: $('#offer_id').length ? $('#offer_id').val() : 0,
                        offer_admin_edit : offer_admin_edit,
                        offer_response_view_society_id : $('#offer_response_view_society_id').length ? $('#offer_response_view_society_id').val() : null
                    }, function(result){
                        if (result) {

                            $formRight.html(result);

                            if (
                                offer_information_sheet_to_be_completed_by_the_subcontractor && 
                                $formRight.find('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').length
                            ) {
                                $formRight.find('#offer_information_sheet_to_be_completed_by_the_subcontractor_field').remove();
                            }

                            Offer.LoadLots();
                            Offer.SubmitFormCheck();
                        }
                    });
                    // <<
                }
            }
        });
        // << ***

        // >> *** SELECTION DE TOUT LES STT DANS LE TABLEAU DES REPONSE DO ***
        $d.off('change', '#offer_response_select_all').on('change', '#offer_response_select_all', function(){

            if ($(this).prop('checked')) {

                $('input[name="offer_response_select[]"]')
                    .attr('checked', 'checked')
                    .prop('checked', true)
                ;
                Offer.CheckSubcontractorSelection();
    
            } else {

                $('input[name="offer_response_select[]"]')
                    .removeAttr('checked')
                    .prop('checked', false)
                ;
                Offer.CheckSubcontractorSelection();
            }
        });
        // << ***
    },

    AjaxLoadFileView : (filepath, callback) => {

        let Ajax = $.ajax({
            url: '/offer/load/file/view/ajax',
            method: 'POST',
            data: {
                file_path : filepath
            },
            dataType: 'HTML'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });

    },

    AjaxFormLoad : (data, callback) => {

        let Ajax = $.ajax({
            url: '/offer/load/form/ajax',
            method: 'POST',
            data: data,
            dataType: 'HTML'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    }
}
module.exports = Offer;