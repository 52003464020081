let AdminNavbar = {
    init : () => {
        AdminNavbar.User();
    },

    User : () => {
        if ($('.navbar-user-email').length && $('.navbar-user-items-list').length) {
            $d.off('click', '.navbar-user-email').on('click', '.navbar-user-email', function(){
                if ($('.navbar-user-items-list').is(':visible')) {
                    $('.navbar-user-items-list').slideUp(200); return;
                }else {
                    $('.navbar-user-items-list').slideDown(200); return;
                }
            });
        }
    },

    Sidebar : () => {
        
        /*if ($('#sidebar').length && $('#admin-navbar').length) {

            $('#sidebar').find('a.btn').each(function(index, item){

                let $item = $(item), href = $(item).attr('href').split('#')[0], origin = window.location.origin;
                let url = window.location.href.split('#')[0];
                    url = url.split('?')[0];

                if (href.length > 1 && url === (origin+href)) {

                    if ($item.parent().attr('class') === 'dropdown-item') {
                        console.log('is-dropdown')
                        console.log($item);
                        $item.addClass('btn-active')
                        $item.parent().parent().parent().children('a.btn').addClass('btn-active');
                        return;
                    } else if ($item.parent().attr('class') === 'list-group-item') {
                        console.log('is-parent-link')
                        $item.addClass('btn-active');
                        return;
                    }
                }
            });
        }*/
    }
}
module.exports = AdminNavbar;